// import type { IUseNetwork } from '@/types/store/useNetwork';
// import { defineStore } from 'pinia';
// import { ref, onMounted, onUnmounted } from 'vue';
// import { Toaster } from '@/util/uiKit/toaster';
// import { apiHeartBit } from '@/util/useApi';

// // 外层变量，代表防抖时长和缓存时长
// const DEBOUNCE_DURATION = 1000; // 防抖时长，单位毫秒
// const CACHE_DURATION = 30 * 1000; // 缓存时长，30秒
// // const CACHE_DURATION = 5 * 60 * 1000; // 缓存时长，5分钟

// export const useNetwork = defineStore('useNetwork', (): IUseNetwork => {
//   const onLine = ref(true);
//   let lastCheckTime = 0;
//   let lastNetworkStatus = true;

//   // 实际网络状态检测函数
//   const checkRealNetworkStatus = async (): Promise<boolean> => {
//     try {
//       const res = await apiHeartBit();
//       console.log(res);
//       return true; // 请求成功表示网络可用
//     } catch (error) {
//       return false; // 捕获异常视为网络不可用
//     }
//   };

//   // 网络状态变更处理函数，防抖和缓存控制
//   const handleNetworkChange = async () => {
//     const now = Date.now();
//     if (now - lastCheckTime < DEBOUNCE_DURATION) return; // 防抖处理
//     lastCheckTime = now;

//     // 缓存检测：网络恢复后 5 分钟内不重复请求
//     if (!onLine.value && lastNetworkStatus === onLine.value) {
//       if (now - lastCheckTime < CACHE_DURATION) return;
//     }

//     // 获取实际网络状态
//     const realStatus = !!(await checkRealNetworkStatus());
//     lastNetworkStatus = realStatus;
//     onLine.value = realStatus;

//     // 根据实际网络状态显示 Toaster
//     if (!realStatus) {
//       Toaster.error('网络已断开');
//     } else {
//       Toaster.success('网络已恢复');
//     }
//   };

//   onMounted(() => {
//     window.addEventListener('online', handleNetworkChange);
//     window.addEventListener('offline', handleNetworkChange);
//     handleNetworkChange(); // 初始化检测
//   });

//   onUnmounted(() => {
//     window.removeEventListener('online', handleNetworkChange);
//     window.removeEventListener('offline', handleNetworkChange);
//   });

//   return {
//     onLine
//   };
// });

import type { IUseNetwork } from '@/types/store/useNetwork';
import { defineStore } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';
import { Toaster } from '@/util/uiKit/toaster';

export const useNetwork = defineStore('useNetwork', (): IUseNetwork => {
  const onLine = ref(true);

  const handleNetworkChange = () => {
    onLine.value = navigator.onLine;
    if (!onLine.value) {
      Toaster.error('网络已断开');
    } else {
      Toaster.success('网络已恢复');
    }
  };

  onMounted(() => {
    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);
  });

  onUnmounted(() => {
    window.removeEventListener('online', handleNetworkChange);
    window.removeEventListener('offline', handleNetworkChange);
  });

  return {
    onLine
  };
});
