/** 子路由的申明集合 */
export const subMetas: Record<string, string[]> = {
  // '/viewHome': ['首页'],
  '/borrowMgr/borrowIn': ['暂借管理', '暂借入库单'],
  '/borrowMgr/borrowInDtl': ['暂借管理', '暂借入库单明细'],
  '/borrowMgr/borrowOut': ['暂借管理', '暂借出库单'],
  '/borrowMgr/borrowOutDtl': ['暂借管理', '暂借出库单明细'],
  '/borrowMgr/borrowNotice': ['暂借管理', '暂借通知单'],
  '/borrowMgr/borrowNoticeDtl': ['暂借管理', '暂借通知单明细'],
  '/costAccounting/costRollover': ['成本核算', '成本结转'],
  '/deliverMgr/deliver': ['送货管理', '送货单'],
  '/deliverMgr/deliverDtl': ['送货管理', '送货单明细'],
  '/deliverMgr/deliverDemand': ['送货管理', '送货需求单'],
  '/deliverMgr/deliverDemandDtl': ['送货管理', '送货需求单明细'],
  '/purchMgr/purchSalesContract': ['采购管理', '购销合同'],
  '/purchMgr/purchSalesContractDtl': ['采购管理', '购销合同明细'],
  '/purchMgr/purchPay': ['采购管理', '采购付款单'],
  '/purchMgr/purchPayDtl': ['采购管理', '采购付款单明细'],
  '/purchMgr/purchIn': ['采购管理', '采购入库单'],
  '/purchMgr/purchInDtl': ['采购管理', '采购入库单明细'],
  '/purchMgr/purchInvc': ['采购管理', '采购发票'],
  '/purchMgr/purchInvcDtl': ['采购管理', '采购发票明细'],
  '/purchMgr/purchContract': ['采购管理', '采购合同'],
  '/purchMgr/purchContractDtl': ['采购管理', '采购合同明细'],
  '/purchMgr/purchDiffAdjust': ['采购管理', '采购差价调整单'],
  '/purchMgr/purchDiffAdjustDtl': ['采购管理', '采购差价调整单明细'],
  '/purchMgr/purchRebateRecord': ['采购管理', '采购返利记录单'],
  '/purchMgr/purchRebateRecordDtl': ['采购管理', '采购返利记录单明细'],
  '/purchMgr/purchNotice': ['采购管理', '采购通知单'],
  '/purchMgr/purchNoticeDtl': ['采购管理', '采购通知单明细'],
  '/purchMgr/purchPrepay': ['采购管理', '采购预付单'],
  '/purchMgr/purchPrepayDtl': ['采购管理', '采购预付单明细'],
  '/salesMgr/promoPriceAudit': ['销售管理', '促销价格审核单'],
  '/salesMgr/promoPriceAuditDtl': ['销售管理', '促销价格审核单明细'],
  '/salesMgr/counterCreditChange': ['销售管理', '往来户信用指标变更单'],
  '/salesMgr/counterCreditChangeDtl': ['销售管理', '往来户信用指标变更单明细'],
  '/salesMgr/receipt': ['销售管理', '收款单'],
  '/salesMgr/returnNotice': ['销售管理', '退货通知单'],
  '/salesMgr/returnNoticeDtl': ['销售管理', '退货通知单明细'],
  '/salesMgr/saleOut': ['销售管理', '销售出库单'],
  '/salesMgr/saleOutDtl': ['销售管理', '销售出库单明细'],
  '/salesMgr/saleInvc': ['销售管理', '销售发票'],
  '/salesMgr/saleInvcDtl': ['销售管理', '销售发票明细'],
  '/salesMgr/saleInvcRebateDtl': ['销售管理', '销售发票返利明细'],
  '/salesMgr/saleReject': ['销售管理', '销售拒收单'],
  '/salesMgr/saleRejectDtl': ['销售管理', '销售拒收单明细'],
  '/salesMgr/saleRedOut': ['销售管理', '销售红冲出库单'],
  '/salesMgr/saleRedOutDtl': ['销售管理', '销售红冲出库单明细'],
  '/salesMgr/saleOrder': ['销售管理', '销售订单'],
  '/salesMgr/saleOrderDtl': ['销售管理', '销售订单明细'],
  '/salesMgr/saleRebate': ['销售管理', '销售返利单'],
  '/salesMgr/saleNotice': ['销售管理', '销售通知单'],
  '/salesMgr/saleNoticeDtl': ['销售管理', '销售通知单明细'],
  '/salesMgr/preReceipt': ['销售管理', '预收单'],
  '/storeMgr/otherIn': ['库存管理', '其他入库单'],
  '/storeMgr/otherInDtl': ['库存管理', '其他入库单明细'],
  '/storeMgr/otherInNotice': ['库存管理', '其他入库通知单'],
  '/storeMgr/otherInNoticeDtl': ['库存管理', '其他入库通知单明细'],
  '/storeMgr/otherOut': ['库存管理', '其他出库单'],
  '/storeMgr/otherOutDtl': ['库存管理', '其他出库单明细'],
  '/storeMgr/otherOutNotice': ['库存管理', '其他出库通知单'],
  '/storeMgr/otherOutNoticeDtl': ['库存管理', '其他出库通知单明细'],
  '/storeMgr/lossGain': ['库存管理', '损益单'],
  '/storeMgr/lossGainDtl': ['库存管理', '损益单明细'],
  '/storeMgr/storeMove': ['库存管理', '移仓单'],
  '/storeMgr/storeMoveDtl': ['库存管理', '移仓单明细'],
  '/storeMgr/storeMoveNotice': ['库存管理', '移仓通知单'],
  '/storeMgr/storeMoveNoticeDtl': ['库存管理', '移仓通知单明细'],
  '/masterMgr/bizTypeSet': ['主数据管理', '业务类型设置'],
  '/masterMgr/employeeSet': ['主数据管理', '人员设置'],
  '/masterMgr/warehouseMgr': ['主数据管理', '仓库设置'],
  '/masterMgr/payTypeSet': ['主数据管理', '付款类型设置'],
  '/masterMgr/storeInOutTypeSet': ['主数据管理', '出入库类型设置'],
  '/masterMgr/reasonCodeTypeSet': ['主数据管理', '原因码类型设置'],
  '/masterMgr/brandMgr': ['主数据管理', '品牌管理'],
  '/masterMgr/projectName': ['主数据管理', '工程名称设置'],
  '/masterMgr/counterparty': ['主数据管理', '往来户'],
  '/masterMgr/counterAddrInfo': ['主数据管理', '往来地址信息'],
  '/masterMgr/invcInfo': ['主数据管理', '增票信息'],
  '/masterMgr/counterPriceType': ['主数据管理', '往来户价格类别'],
  '/masterMgr/prodPriceType': ['主数据管理', '物料价格类别'],
  '/masterMgr/prodUnit': ['主数据管理', '物料单位'],
  '/masterMgr/prodTypeSet': ['主数据管理', '物料类别设置'],
  '/masterMgr/prodCategory': ['主数据管理', '物料类型设置'],
  '/masterMgr/productMgr': ['主数据管理', '物料设置'],
  '/masterMgr/prodPrice': ['主数据管理', '物料价格'],
  '/masterMgr/counterPriceSet': ['主数据管理', '往来户价格设置'],
  '/masterMgr/industrySet': ['主数据管理', '行业设置'],
  '/masterMgr/orderTypeSet': ['主数据管理', '订单类型设置'],
  '/masterMgr/rebateType': ['主数据管理', '返利类型'],
  '/masterMgr/deliveryType': ['主数据管理', '送货类型'],
  '/masterMgr/departmentSet': ['主数据管理', '部门设置'],
  '/masterMgr/bankAcct': ['主数据管理', '银行账号'],
  '/masterMgr/salesTypeSet': ['主数据管理', '销售类型设置'],
  '/sysSetting/bizParams': ['系统设置模块', '业务参数'],
  '/sysSetting/authCenter': ['系统设置模块', '授权中心'],
  '/sysSetting/prodCounterPriceMaintain': ['系统设置模块', '物料往来价格维护'],
  '/afterService/afterServCompensSf/afterServCompensOut': ['售后服务', '售后赔偿(上房)', '售后赔偿出库单'],
  '/afterService/afterServCompensSf/afterServCompensNotice': ['售后服务', '售后赔偿(上房)', '售后赔偿通知单'],
  '/afterService/claimDtao/claimOut': ['售后服务', '索赔(东陶)', '索赔出库单'],
  '/afterService/claimDtao/claimExecution': ['售后服务', '索赔(东陶)', '索赔执行情况'],
  '/afterService/claimDtao/claimNotice': ['售后服务', '索赔(东陶)', '索赔通知单'],
  '/afterService/productReplaceMaintainDtao/goodsOut': ['售后服务', '产品更换维修(东陶)', '好品出库单'],
  '/afterService/productReplaceMaintainDtao/goodsOutDtl': ['售后服务', '产品更换维修(东陶)', '好品出库单明细'],
  '/afterService/productReplaceMaintainDtao/maintainNotice': ['售后服务', '产品更换维修(东陶)', '维修通知单'],
  '/afterService/productReplaceMaintainDtao/maintainNoticeDtl': ['售后服务', '产品更换维修(东陶)', '维修通知单明细'],
  '/afterService/productReplaceMaintainDtao/consumedIn': ['售后服务', '产品更换维修(东陶)', '耗损品入库'],
  '/afterService/productReplaceMaintainDtao/consumedInDtl': ['售后服务', '产品更换维修(东陶)', '耗损品入库明细'],
  '/counterAcctMgr/accQuery/accountsDtlReceivable': ['往来账管理', '往来查询', '往来明细账-应收'],
  '/counterAcctMgr/accQuery/rptAccountsQuota': ['往来账管理', '往来查询', '往来额度综合报表'],
  '/counterAcctMgr/accountsClearance/payableClearanceSummary': ['往来账管理', '往来核销', '应付款核销(汇总)'],
  '/counterAcctMgr/accountsClearance/receivableClearanceSummary': ['往来账管理', '往来核销', '应收款核销(汇总)'],
  '/counterAcctMgr/bizBill/payment': ['往来账管理', '业务单据', '付款单据'],
  '/counterAcctMgr/bizBill/paymentDtl': ['往来账管理', '业务单据', '付款单据明细'],
  '/counterAcctMgr/bizBill/recivBill': ['往来账管理', '业务单据', '收款单据'],
  '/counterAcctMgr/bizBill/purchaseInvcBlue': ['往来账管理', '业务单据', '采购发票蓝字'],
  '/counterAcctMgr/bizBill/purchaseInvcBlueDtl': ['往来账管理', '业务单据', '采购发票蓝字明细'],
  '/counterAcctMgr/bizBill/saleInvcBlue': ['往来账管理', '业务单据', '销售发票蓝字'],
  '/counterAcctMgr/bizBill/saleInvcBlueDtl': ['往来账管理', '业务单据', '销售发票蓝字明细'],
  '/counterAcctMgr/bizBill/saleInvcBlueDtl1': ['往来账管理', '业务单据', '销售发票蓝字明细1'],
  '/rptView/rptAfterServ/rptProductMaintain': ['报表查询', '售后报表', '产品维修执行报表'],
  '/rptView/rptAfterServ/rptAfterServiceCompensation': ['报表查询', '售后报表', '售后赔偿执行情况'],
  '/rptView/rptAfterServ/claimExecution': ['报表查询', '售后报表', '索赔执行情况'],
  '/rptView/rptBorrow/rptBorrowExecution': ['报表查询', '暂借报表', '暂借执行情况'],
  '/rptView/rptOther/rptInvcPushExceptionQuery': ['报表查询', '其他报表', '发票下推数异常查询'],
  '/rptView/rptOther/rptInvcListQuery': ['报表查询', '其他报表', '发票清单查询'],
  '/rptView/rptOther/rptItemPriceDtl': ['报表查询', '其他报表', '商品价格详情表'],
  '/rptView/rptOther/rptItemTypeSaleDiscoStatistics': ['报表查询', '其他报表', '商品类型销售折扣统计表'],
  '/rptView/rptOther/rptTransferNoticeQuery': ['报表查询', '其他报表', '移仓通知单查询'],
  '/rptView/rptOther/rptFinanceInvcCheckDtl': ['报表查询', '其他报表', '财务发票核对明细'],
  '/rptView/rptOther/rptSaleNoticeQuery': ['报表查询', '其他报表', '销售通知单情况查询'],
  '/rptView/rptOther/rptNoticeContactInfo': ['报表查询', '其他报表', '销售通知单联系人信息报表'],
  '/rptView/rptProdType/rptDeptSaleProfitByType': ['报表查询', '商品类型报表', '部门销售利润表(类型)'],
  '/rptView/rptProdType/rptPurchaseContByType': ['报表查询', '商品类型报表', '采购合同报表(类型)'],
  '/rptView/rptProdType/rptSaleDtlSummaryByType': ['报表查询', '商品类型报表', '销售明细汇总表(类型)'],
  '/rptView/rptProdType/rptSaleNoticeQueryByType': ['报表查询', '商品类型报表', '销售通知单查询(类型)'],
  '/rptView/rptPurch/rptPayDtl': ['报表查询', '采购报表', '付款单明细表'],
  '/rptView/rptPurch/rptItemPurchaseQtySummary': ['报表查询', '采购报表', '商品采购数量汇总报表'],
  '/rptView/rptPurch/rptPurchaseNoticeOpenedNotIn': ['报表查询', '采购报表', '已开采购通知单未入库报表'],
  '/rptView/rptPurch/rptPurchaseInvcDtl': ['报表查询', '采购报表', '采购发票明细表'],
  '/rptView/rptPurch/rptPurchaseCont': ['报表查询', '采购报表', '采购合同报表'],
  '/rptView/rptPurch/rptPurchaseContNoInvcDtl': ['报表查询', '采购报表', '采购合同未开票明细表'],
  '/rptView/rptPurch/rptPurchaseShortage': ['报表查询', '采购报表', '采购欠货表'],
  '/rptView/rptPurch/rptPurchaseRebate': ['报表查询', '采购报表', '采购返利报表'],
  '/rptView/rptSales/rptBranchSaleQuery': ['报表查询', '销售报表', '分店销售情况查询'],
  '/rptView/rptSales/rptBillExecution': ['报表查询', '销售报表', '单据执行情况表'],
  '/rptView/rptSales/rptItemCategorySaleAmount': ['报表查询', '销售报表', '商品大类销售额报表'],
  '/rptView/rptSales/rptSlowItem': ['报表查询', '销售报表', '商品滞销表'],
  '/rptView/rptSales/rptCustSaleList': ['报表查询', '销售报表', '客户销售清单'],
  '/rptView/rptSales/rptSoldNotTakenDtl': ['报表查询', '销售报表', '已售未取明细表'],
  '/rptView/rptSales/rptOrderedNotInvcedDtl': ['报表查询', '销售报表', '已开单未开票明细报表'],
  '/rptView/rptSales/rptItemDtlOfOrder': ['报表查询', '销售报表', '开单商品明细表'],
  '/rptView/rptSales/rptDailySaleAmountSummary': ['报表查询', '销售报表', '每日销售额汇总表'],
  '/rptView/rptSales/rptItemPromoPriceQuery': ['报表查询', '销售报表', '物料促销价格查询'],
  '/rptView/rptSales/rptContactInfo': ['报表查询', '销售报表', '联系人信息报表'],
  '/rptView/rptSales/rptPurchaseSaleContExecution': ['报表查询', '销售报表', '购销合同执行情况'],
  '/rptView/rptSales/rptRebateUseList': ['报表查询', '销售报表', '返利使用清单'],
  '/rptView/rptSales/rptNoticeOutInfo': ['报表查询', '销售报表', '通知单出库信息表'],
  '/rptView/rptSales/rptDeptSaleProfit': ['报表查询', '销售报表', '部门销售利润表'],
  '/rptView/rptSales/rptSaleOutDtl': ['报表查询', '销售报表', '销售出库明细表'],
  '/rptView/rptSales/rptSaleInvcRed': ['报表查询', '销售报表', '销售发票报表(含红字)'],
  '/rptView/rptSales/rptSaleInvc': ['报表查询', '销售报表', '销售发票报表'],
  '/rptView/rptSales/rptSaleDtlSummary': ['报表查询', '销售报表', '销售明细汇总表'],
  '/rptView/rptSales/rptSaleOrderExecution': ['报表查询', '销售报表', '销售订单执行情况'],
  '/rptView/rptSales/rptSaleRebateSummary': ['报表查询', '销售报表', '销售返利汇总表'],
  '/rptView/rptSales/rptSaleAmountDtlByCust': ['报表查询', '销售报表', '销售额明细表(按客户)'],
  '/rptView/rptSales/rptSaleAmountSummaryByCust': ['报表查询', '销售报表', '销售额汇总表(按客户)'],
  '/rptView/rptStore/rptTotoInventoryList': ['报表查询', '库存报表', 'TOTO库存清单报表'],
  '/rptView/rptStore/rptFullSoldNotTakenDtl': ['报表查询', '库存报表', '全口径已售未取明细报表'],
  '/rptView/rptStore/rptOtherInExecution': ['报表查询', '库存报表', '其他入库执行情况表'],
  '/rptView/rptStore/rptOtherOutExecution': ['报表查询', '库存报表', '其他出库执行情况表'],
  '/rptView/rptStore/rptInOutDtl': ['报表查询', '库存报表', '出入库单明细表'],
  '/rptView/rptStore/rptItemAvailInventoryDtl': ['报表查询', '库存报表', '商品可用库存明细账'],
  '/rptView/rptStore/rptItemInventoryDtl': ['报表查询', '库存报表', '商品库存明细账'],
  '/rptView/rptStore/rptInventoryList': ['报表查询', '库存报表', '库存清单报表'],
  '/rptView/rptStore/rptItemPriceComprehensiveQuery': ['报表查询', '库存报表', '物料价格综合查询'],
  '/rptView/rptStore/rptItemNotEnabledQuery': ['报表查询', '库存报表', '物料未启用查询'],
  '/rptView/rptStore/rptInventoryCombineTableSFJU': ['报表查询', '库存报表', '盘点合并库存表(SF&JU)'],
  '/rptView/rptStore/rptTransferExecution': ['报表查询', '库存报表', '移仓执行情况表'],
  '/rptView/rptStore/rptRedOutverification': ['报表查询', '库存报表', '红字出库核对表'],
  '/rptView/rptStore/rptInventoryDaily': ['报表查询', '库存报表', '进销存日报表'],
  '/rptView/rptStore/rptInventoryMonthly': ['报表查询', '库存报表', '进销存月报表'],
  '/salesMgr/saleInvcPrt': ['销售管理', '销售发票清单自助打印'],
  '/deliverMgr/deliverInfoUpdate': ['送货管理', '送货信息更新'],
  '/deliverMgr/deliverDemandQuery': ['送货管理', '送货需求查询'],
  '/deliverMgr/deliverPrepBatchPrt': ['送货管理', '送货准备单(批量打印)'],
  '/costAccounting/monthlyCostViewAdjust': ['成本核算', '月度成本数据查看和调整'],
  '/rptView/rptPurch': ['报表查询', '采购报表'],
  '/rptView/rptSales': ['报表查询', '销售报表'],
  '/rptView/rptStore': ['报表查询', '库存报表'],
  '/rptView/rptAfterServ': ['报表查询', '售后报表'],
  '/rptView/rptBorrow': ['报表查询', '暂借报表'],
  '/rptView/rptOther': ['报表查询', '其他报表'],
  '/rptView/rptProdType': ['报表查询', '商品类型报表'],
  '/sysSetting/dictImportExport': ['系统设置模块', '字典导入导出'],
  '/sysSetting/exceptionBillQuery': ['系统设置模块', '单据异常数据查询'],
  '/sysSetting/sysUser': ['系统设置模块', '操作员管理'],
  '/sysSetting/sysRole': ['系统设置模块', '角色管理'],
  '/sysSetting/sysRoleAuth': ['系统设置模块', '角色权限设置'],
  '/sysSetting/bizBills': ['系统设置模块', '业务单据清单']
};
