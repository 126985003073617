import type { IPostOpt, TTaskTag } from '@/types/worker';
import { Toaster } from '@/util/uiKit/toaster';
import type { IResData } from 'shangshi_types';
import * as WorkerSet from '@/webWorker';

/** 对 web worker 获取 postMessage 的参数封装
 *
 * @param payload
 * @returns
 */
const getPostOpt = <T = any>(payload?: T): IPostOpt => {
  const token = sessionStorage.getItem('token') || '';
  const urlFrom = window.location.origin;
  return {
    token,
    urlFrom,
    payload
  };
};

/** 发起一个 web worker 任务
 *
 * @param taskTag 任务简称
 * @param payload 任务数据载荷
 * @returns
 */
export const startWorker = <T = any, U = any>(taskTag: TTaskTag, payload?: T): Promise<U> => {
  return new Promise((resolve, reject) => {
    const WorkerObj = WorkerSet as Record<string, any>;
    const { default: MyWorker } = WorkerObj[taskTag];
    const worker = new MyWorker();

    const postOpt = getPostOpt<T>(payload);
    // 发送消息给 Worker
    worker.postMessage(postOpt);

    // 接收 Worker 的消息
    worker.onmessage = (e: MessageEvent) => {
      const { data, isErr, msg } = (e.data || {}) as IResData;
      if (isErr) {
        Toaster.error(msg);
        reject(new Error(msg));
      } else {
        resolve(data as U);
      }
    };

    worker.onerror = (err: ErrorEvent) => {
      Toaster.error(err.message);
      reject(err);
    };
  });
};
