import type { IHomeInfo, ISearchRecursion, ISearchRes, IUseCache, THookUseCache } from '@/types/hook/useCache';
import type { ICounterCache, IKvTreeRes, IMenuItem, IOrmBase, IOrmKvDict, IProductCache, IProductCacheLite, IStoreCache, ITreeItem, TCounterPartSelectRange, TTreeCacheType, biz } from 'shangshi_types';
import { apiGetKvSource, apiGetBizParam, apiGetCounterTree, apiGetProductTree, apiGetStoreTree, apiGetProductList, apiGetListSetting } from '@/util/useApi';
import { KEY_GLOBAL_BIZ_PARAM, KEY_HOME_INFO, KEY_MENU_CACHE_FLAT } from '@/constant/sessionKey';
import { delCache, getCache, setCache } from '@/util/cacheKit/getCache';
import { getKvKeyAlias } from 'shangshi_bizproto';
import type { TBizIdent } from '@/types/alias';

const KV_TREE = {
  counter: 'masterCounterparty_tree',
  product: 'masterProductMgr_tree',
  store: 'masterWarehouseMgr_tree'
};
const getKeyAliasMap = (key: string): string => `${getKvKeyAlias(key)}_map`;
const KEY_BIZ_BILLS = 'bizBills';

const kvSourceCache: Map<string, IOrmKvDict[]> = new Map();
const kvSourceMapCache: Map<string, IOrmKvDict> = new Map();
const kvSourceTree: Map<string, TTreeCacheType[]> = new Map();
const sysBizParamCache: Map<string, biz.setting.sysSetting.ISysBizParam> = new Map();
const menuCache: Record<string, IMenuItem> = {};
const counterRoot: Map<TCounterPartSelectRange, number[]> = new Map();
const homeInfo: IHomeInfo = {
  post: [],
  remind: [],
  birthDay: []
};

/** 从集合中过滤出符合筛选条件的物料
 *
 * @param searchKey 筛选关键词
 * @param arr 集合
 * @returns
 */
const getMatchProduct = (searchKey: string, arr: IProductCache[]): IProductCache[] => {
  const resArr: IProductCache[] = [];
  for (const v of arr) {
    const spec = ((v as IProductCache).spec || '').toLowerCase();
    const prodIdNamezh = ((v as IProductCache).prodIdNamezh || '').toLowerCase();
    if ((spec === searchKey) || (prodIdNamezh === searchKey)) {
      resArr.push(v);
      break;
    } else if (spec.includes(searchKey) || prodIdNamezh.includes(searchKey)) {
      resArr.push(v);
    }
  }
  return resArr;
};

const filterMatch = (item: ITreeItem, keyword: string, dataType: 'counter' | 'store' | 'product'): boolean => {
  const fStr = keyword.toLowerCase();
  if (dataType === 'product') return (item.spec as string | undefined || '').toLowerCase().includes(fStr) || `${((item as any).prodIdNamezh || (item as any).title || '').toLowerCase()}`.includes(fStr);
  return item.title.toLowerCase().includes(fStr);
};

// 树筛选过程的递归函数
const loopToArr = (keyword: string, data: ITreeItem[], dataType: 'counter' | 'store' | 'product'): ISearchRecursion => {
  const result: ITreeItem[] = [];
  const leafs: ITreeItem[] = [];
  data.forEach((item: ITreeItem) => {
    // if (item.title.toLowerCase().includes(keyword)) {
    if (filterMatch(item, keyword, dataType)) {
      result.push({ ...item });
      const arr = result.filter((v: ITreeItem) => v.isLeaf);
      if (arr.length) leafs.push(...arr);
    } else if (item.children) {
      const { tree: filterData, leafs: childLeafs } = loopToArr(keyword, item.children, dataType);
      if (filterData.length) {
        result.push({
          ...item,
          children: filterData
        });
        if (childLeafs.length) leafs.push(...childLeafs);
      }
    }
  });
  return { tree: result, leafs  };
};

// 树筛选过程的递归函数
const loopById = (id: number, data: ITreeItem[]): ITreeItem | undefined => {
  const result: ITreeItem[] = [];
  for (const item of data) {
    if (item.id === id) {
      result.push(item);
      break;
    } else if (item.children) {
      const obj = loopById(id, item.children);
      if (obj) {
        result.push(obj);
        break;
      }
    }
  }
  return result[0];
};

const loopToLeaf = (data:ITreeItem[]): number[] => {
  const result: number[] = [];
  for (const item of data) {
    if (item.isLeaf) {
      result.push(item.id);
    } else if (item.children) {
      const arr = loopToLeaf(item.children);
      if (arr.length) result.push(...arr);
    }
  }
  return result;
};

/** hook_入口文件 */
export const useCache: THookUseCache = (): IUseCache => {
  /** 刷新系统设置中的业务参数缓存 */
  const refeshSysBizParams = async (): Promise<void> => {
    await delCache('kvSource', KEY_GLOBAL_BIZ_PARAM);
    sysBizParamCache.delete(KEY_GLOBAL_BIZ_PARAM);
    const cache = await apiGetBizParam();
    await setCache('kvSource', KEY_GLOBAL_BIZ_PARAM, cache);
    sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cache);
  };

  /** 获取系统设置中的业务参数 */
  const getSysBizParams = async (): Promise<biz.setting.sysSetting.ISysBizParam> => {
    const sysBizParam: biz.setting.sysSetting.ISysBizParam | undefined = sysBizParamCache.get(KEY_GLOBAL_BIZ_PARAM);
    if (sysBizParam) return sysBizParam;

    const cache = await getCache('kvSource', KEY_GLOBAL_BIZ_PARAM);
    if (cache) {
      sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cache);
      return cache;
    } else {
      const cacheRmt = await apiGetBizParam();
      sysBizParamCache.set(KEY_GLOBAL_BIZ_PARAM, cacheRmt);
      await setCache('kvSource', KEY_GLOBAL_BIZ_PARAM, cacheRmt);
      return cacheRmt;
    }
  };

  /** 获取当前已登录用户的菜单缓存 */
  const getMenus = (bizIdent: TBizIdent): IMenuItem | undefined => {
    if (!Object.keys(menuCache).length) {
      const cacheStr = sessionStorage.getItem(KEY_MENU_CACHE_FLAT);
      if (cacheStr) {
        const arr = Object.entries(JSON.parse(cacheStr));
        for (const v of arr) {
          const [a, b] = v;
          menuCache[a] = b as IMenuItem;
        }
      }
    }
    return menuCache[bizIdent];
  };

  /** 获取首页信息缓存 */
  const initHomeInfo = async (): Promise<void> => {
    if (!homeInfo.done) {
      // const { post = [], remind = [], birthDay = [] } = await startWorker('homeInfo');
      // homeInfo.post = post;
      // homeInfo.remind = remind;
      // homeInfo.birthDay = birthDay;
      // homeInfo.done = true;
      // const { post: postList, remind: remindList, birthDay: birthDayList } = homeInfo;
      // sessionStorage.setItem(KEY_HOME_INFO, JSON.stringify({ post: postList, remind: remindList, birthDay: birthDayList }));
    }
  };

  /** 获取首页信息缓存 */
  const getHomeInfo = (): IHomeInfo => {
      const strCache = sessionStorage.getItem(KEY_HOME_INFO) || '{}';
      return homeInfo.done ? homeInfo : JSON.parse(strCache) as IHomeInfo;
  };

  /** 从缓存或远端获取列表形态的 kv 数据
   *
   * @param dictKeys 字典 key
   * @param masterKeys 主数据 key
   * @returns
   */
  const getKvSourceList = async (dictKeys: string[], masterKeys: string[]): Promise<Record<string, IOrmKvDict[]>> => {
    const obj: Record<string, IOrmKvDict[]> = {};
    const keyAlias: Record<string, string> = {};

    const unCachedDictKeys: string[] = [];
    const unCachedMasterKeys: string[] = [];
    for (const v of dictKeys) {
      const cacheKey = getKvKeyAlias(v);
      keyAlias[v] = cacheKey;
      const dict: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);  // 优先读取内存缓存
      if (dict) {
        obj[v] = dict;
      } else {
        const cache = await getCache('kvSource', cacheKey);  // 若内存缓存不存在，则读取浏览器缓存
        if (cache) {
          kvSourceCache.set(cacheKey, cache);
          obj[v] = cache;
        } else { // 若浏览器缓存也不存在，则记录远端读取参数
          unCachedDictKeys.push(v);
        }
      }
    }
    for (const v of masterKeys) {
      const cacheKey = getKvKeyAlias(v);
      keyAlias[v] = cacheKey;
      const master: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);  // 优先读取内存缓存
      if (master) {
        obj[v] = master;
      } else {
        const cache = await getCache('kvSource', cacheKey);  // 若内存缓存不存在，则读取浏览器缓存
        if (cache) {
          kvSourceCache.set(cacheKey, cache);
          obj[v] = cache;
        } else { // 若浏览器缓存也不存在，则记录远端读取参数
          unCachedMasterKeys.push(v);
        }
      }
    }
    if (unCachedDictKeys.length || unCachedMasterKeys.length) { // 发起远端请求
      const cacheRmt: Record<string, IOrmKvDict[]> = await apiGetKvSource(unCachedDictKeys, unCachedMasterKeys);
      const arrKeys = [...unCachedDictKeys, ...unCachedMasterKeys];
      for (const v of arrKeys) {
        const cacheKey = keyAlias[v];
        kvSourceCache.set(cacheKey, cacheRmt[cacheKey]); // 写入内存缓存
        await setCache('kvSource', cacheKey, cacheRmt[cacheKey]);  // 写入浏览器缓存
        obj[v] = cacheRmt[cacheKey];
      }
    }

    return obj;
  };

  /** 从缓存或远端获取列表形态的 bizBills 数据集合
   *
   * @param idRange 允许加载的 id 范围
   */
  const getBizBillList = async (idRange?: number[]): Promise<IOrmKvDict[]> => {
    const obj: { bills: IOrmKvDict[] | undefined } = { bills: [] };
    obj.bills = kvSourceCache.get(KEY_BIZ_BILLS);
    if (!obj.bills) {
      const arr = (await apiGetListSetting<IOrmBase>('bizBills') as IOrmBase[]).map((v: IOrmBase): IOrmKvDict => {
        return {
          key: v.id,
          title: v.namezh,
          isTextKey: false
        };
      });
      kvSourceCache.set(KEY_BIZ_BILLS, arr);
      await setCache('kvSource', KEY_BIZ_BILLS, obj.bills);  // 写入浏览器缓存
      obj.bills = arr;
    }
    return !idRange ? obj.bills : obj.bills.filter(v => idRange.includes(+(v.key)));
  };

  // /** 从缓存或远端获取关键词过滤的物料主数据的扁平集合
  //  *
  //  * @param key 主数据 key
  //  * @returns
  //  */
  // const getFlatProductBySearchKey = async (key?: string): Promise<IProductCache[]> => {
  //   const cacheKey = KV_TREE.product;
  //   const searchKey = (key || '').toLowerCase();
  //   const kv: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);
  //   if (!kv) {
  //     const cacheKv = await getCache('kvSource', cacheKey);
  //     if (cacheKv) {
  //       kvSourceCache.set(cacheKey, cacheKv);
  //       if (!key) return cacheKv;
  //       return getMatchProduct(searchKey, cacheKv);
  //     } else {
  //       const treeList = ((await apiGetProductList()) || []);
  //       kvSourceCache.set(cacheKey, treeList as IOrmKvDict[]);
  //       await setCache('kvSource', cacheKey, treeList);
  //     }
  //   }
  //   const arr = kvSourceCache.get(cacheKey) as unknown[];
  //   if (!key) return arr as IProductCache[];
  //   return getMatchProduct(searchKey, arr as IProductCache[]);
  // };

  /** 从缓存或远端获取关键词过滤的物料主数据的树状集合
   *
   * @param key 主数据 key
   * @returns
   */
  const getProductBySearchKey = async (key?: string): Promise<IProductCache[]> => {
    const cacheKey = KV_TREE.product;
    const searchKey = (key || '').toLowerCase();
    const kv: IOrmKvDict[] | undefined = kvSourceCache.get(cacheKey);
    if (!kv) {
      const cacheKv = await getCache('kvSource', cacheKey);
      if (cacheKv) {
        kvSourceCache.set(cacheKey, cacheKv);
        if (!key) return cacheKv;
        return getMatchProduct(searchKey, cacheKv);
      } else {
        const treeList = ((await apiGetProductList()) || []);
        kvSourceCache.set(cacheKey, treeList as IOrmKvDict[]);
        await setCache('kvSource', cacheKey, treeList);
      }
    }
    const arr = kvSourceCache.get(cacheKey) as unknown[];
    if (!key) return arr as IProductCache[];
    return getMatchProduct(searchKey, arr as IProductCache[]);
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据 */
  const getKvTreeProduct = async (): Promise<IProductCache[]> => {
    const cacheKey = KV_TREE.product;
    const kv: IProductCache[] | undefined = kvSourceTree.get(cacheKey) as IProductCache[];
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceTree.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const { treeList } = (await apiGetProductTree()) || {} as IKvTreeRes;
    kvSourceTree.set(cacheKey, treeList as IProductCache[]);
    await setCache('kvSource', cacheKey, treeList);
    return treeList as IProductCache[];
  };

  /** 从缓存或远端获取关键词过滤的物料数据的树状集合
   *
   * @param key 主数据 key
   * @returns
   */
  const searchProductByKey = async (key?: string): Promise<ISearchRes<IProductCache>> => {
    const searchKey = (key || '').toLowerCase();
    const arr = await getKvTreeProduct();
    if (!key) return { tree: arr as IProductCache[] };

    const { tree, leafs } = loopToArr(searchKey, arr, 'product');
    const obj: ISearchRes<any> = { tree };
    if (leafs.length === 1) obj.onlyLeaf = leafs[0];
    return obj;
  };

  /** 从缓存或远端获取键值对形态的 kv 主数据
   *
   * @param key 主数据 key
   * @returns
   */
  const getKvSourceMap = async (key: string): Promise<IOrmKvDict> => {
    const cacheKey = getKeyAliasMap(key);
    const kv: IOrmKvDict | undefined = kvSourceMapCache.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceMapCache.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const arr: IOrmKvDict[] = (await getKvSourceList([], [key]))?.[key] || [];
    const obj: IOrmKvDict | undefined = arr.find((v: IOrmKvDict) => v.key === key);
    // kvSourceMapCache.set(cacheKey, obj);
    return obj || {} as IOrmKvDict;
  };

  const getCounterByRange = (list: ICounterCache[], selectRange?: TCounterPartSelectRange): ICounterCache[] => {
    if (!selectRange) return list;
    const arr = counterRoot.get(selectRange) || [];
    return list.filter((v: ICounterCache) => arr.includes(v.id));
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据
   *
   * @param key 主数据 key
   */
  const getKvTreeCounter = async (selectRange?: TCounterPartSelectRange): Promise<ICounterCache[]> => {
    const cacheKey = KV_TREE.counter;
    const kv: ICounterCache[] | undefined = kvSourceTree.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    const cacheCounterRoot = await getCache('counterRoot', (selectRange || 'all') as TCounterPartSelectRange);
    if (cacheKv && cacheCounterRoot) {
      kvSourceTree.set(cacheKey, cacheKv);
      return getCounterByRange(cacheKv, selectRange);
    }

    const { treeList, treeRoots = {} } = (await apiGetCounterTree()) || {} as IKvTreeRes;
    kvSourceTree.set(cacheKey, treeList);
    await setCache('kvSource', cacheKey, treeList);
    const arrRoots = Object.entries(treeRoots);
    for (const v of arrRoots) {
      const [a, b] = v as [TCounterPartSelectRange, number[]];
      counterRoot.set(a, b);
    }
    return getCounterByRange(treeList, selectRange);
  };

  /** 从缓存或远端获取关键词过滤的往来户主数据的树状集合
   *
   * @param key 主数据 key
   * @param range 往来户范围
   * @returns
   */
  const searchCounterByKey = async (key: string, range?: TCounterPartSelectRange): Promise<ISearchRes<ICounterCache>> => {
    const searchKey = (key || '').toLowerCase();
    const arr = await getKvTreeCounter(range);
    if (!key) return { tree: arr as ICounterCache[] };

    const { tree, leafs } = loopToArr(searchKey, arr, 'counter');
    const obj: ISearchRes<ICounterCache> = { tree };
    if (leafs.length === 1) obj.onlyLeaf = leafs[0];
    return obj;
  };

  /** 从缓存或远端获取关键词过滤的往来户主数据的扁平备选集合
   *
   * @param key 主数据 key
   * @param range 往来户范围
   * @returns
   */
  const getCounterById = async (id: number, range?: TCounterPartSelectRange): Promise<ICounterCache | undefined> => {
    if (id > 0) {
      const arr = await getKvTreeCounter(range);

      return loopById(id, arr);
    }
  };

  /** 从缓存或远端获取往来户的所有叶结点的id集合
   *
   * @param opt { dataType: 数量类型， range?: 往来户范围，仅当 dataType 为 counter 时适用 }
   * @returns
   */
  const getAllLeafIds = async (opt: { dataType: 'counter' | 'stroe', range?: TCounterPartSelectRange }): Promise<number[]> => {
    const arr = opt.dataType === 'counter' ? await getKvTreeCounter(opt.range) : await getKvTreeStore();
    return loopToLeaf(arr);
  };

  /** 从缓存或远端获取树状形态往来户的 kv 主数据 */
  const getKvTreeStore = async (): Promise<IStoreCache[]> => {
    const cacheKey = KV_TREE.store;
    const kv: IStoreCache[] | undefined = kvSourceTree.get(cacheKey);
    if (kv) return kv;
    const cacheKv = await getCache('kvSource', cacheKey);
    if (cacheKv) {
      kvSourceTree.set(cacheKey, cacheKv);
      return cacheKv;
    }
    const { treeList } = (await apiGetStoreTree()) || {} as IKvTreeRes;
    kvSourceTree.set(cacheKey, treeList);
    await setCache('kvSource', cacheKey, treeList);
    return treeList;
  };

  /** 从缓存或远端获取关键词过滤的仓库主数据的扁平备选集合
   *
   * @param key 主数据 key
   * @returns
   */
  const getStoreById = async (id: number): Promise<IStoreCache | undefined> => {
    if (id > 0) {
      const arr = await getKvTreeStore();

      return loopById(id, arr);
    }
  };

  /** 从缓存或远端获取关键词过滤的仓库数据的树状集合
   *
   * @param key 主数据 key
   * @returns
   */
  const searchStoreByKey = async (key: string): Promise<ISearchRes<IStoreCache>> => {
    const searchKey = (key || '').toLowerCase();
    const arr = await getKvTreeStore();
    if (!key) return { tree: arr as IStoreCache[] };

    const { tree, leafs } = loopToArr(searchKey, arr, 'store');
    const obj: ISearchRes<IStoreCache> = { tree };
    if (leafs.length === 1) obj.onlyLeaf = leafs[0];
    return obj;
  };

  /** 刷新本地的指定键值缓存 */
  const refrashCache = async (key: string) => {
    const cacheKey = ((key === 'counter') && 'masterCounterparty')
      || ((key === 'product') && 'masterProductMgr')
      || ((key === 'store') && 'masterWarehouseMgr')
      || getKvKeyAlias(key);

    const cacheKeyMap = ((key === 'counter') && getKeyAliasMap(key))
      || ((key === 'product') && getKeyAliasMap(key))
      || ((key === 'store') && getKeyAliasMap(key))
      || '';

    const cacheKeyTree = ((key === 'counter') && KV_TREE.counter)
      || ((key === 'product') && KV_TREE.product)
      || ((key === 'store') && KV_TREE.store)
      || '';

    kvSourceCache.delete(cacheKey);
    await delCache('kvSource', cacheKey);

    if (cacheKeyMap) {
      kvSourceMapCache.delete(cacheKeyMap);
      await delCache('kvSource', cacheKeyMap);
    }

    if (cacheKeyTree) {
      kvSourceTree.delete(cacheKeyTree);
      await delCache('kvSource', cacheKeyTree);
    }

    await getKvSourceList([], [key]);
  };

  /** 获取当前用户的当前业务序时簿的列定义
   *
   * @param bizident 业务标识
   * @param userId 用户ID
   * @returns
   */
  const getCustomerCol = async (bizident: string, userId: number): Promise<string> => {
    const cache = await getCache('customerCol', `${bizident}_${userId}`);
    return cache || '';
  };

  /** 设置当前用户的当前业务序时簿的列定义
   *
   * @param bizident 业务标识
   * @param userId 用户ID
   * @returns
   */
  const setCustomerCol = async (bizident: string, userId: number, val: string[] | string): Promise<void> => {
    const str = Array.isArray(val) ? val.join(',') : val as string;
    await setCache('customerCol', `${bizident}_${userId}`, str);
  };

  return {
    refeshSysBizParams,
    getSysBizParams,
    getMenus,
    initHomeInfo,
    getHomeInfo,
    getKvSourceList,
    getBizBillList,
    getProductBySearchKey,
    getKvTreeProduct,
    searchProductByKey,
    getKvSourceMap,
    getKvTreeCounter,
    searchCounterByKey,
    getCounterById,
    getAllLeafIds,
    getKvTreeStore,
    getStoreById,
    searchStoreByKey,
    refrashCache,
    getCustomerCol,
    setCustomerCol
  };
};

/** 为产品选择弹窗提供值源筛选
 *
 * @param key 筛选关键词
 * @param list 值源
 * @returns
 */
export const filterProductByKey = (key: string, list: IProductCacheLite[]): ISearchRes<IProductCacheLite> => {
  const searchKey = (key || '').toLowerCase();
  if (!key) return { tree: list as IProductCacheLite[] };

  const { tree, leafs } = loopToArr(searchKey, list, 'product');
  const obj: ISearchRes<IProductCacheLite> = { tree };
  if (leafs.length === 1) obj.onlyLeaf = leafs[0];
  return obj;
};
