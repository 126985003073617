import type { IMasterTree, IOrmBase, IAuthRes, IAuthSource, IOrmKvDict, IResData, ISignRes, ITreeItem, IUserSingnInfo, IHisRes, TUserAccMgrType, TRoleMgrType, TRemindTag, IPermissonPayload, IIdNamezh, IKvTreeRes, TEditPayload, TPayload, TClearanceType, IClearancePayload, IClearance, IMenuItem, IPrePushNoticeParam } from 'shangshi_types';
import type { AxiosInstance } from 'axios';
import type { TApiDataType } from '@/types/store/useApp';
import type { IRemind } from '@/types/biz/remind';
import type { ISysBizParam } from 'shangshi_types/src/tBiz/tSysSetting';
import type { IStoryDaliyRes } from '@/types/store/useStore';
import type { ICostProfile } from 'shangshi_types/src/tBiz';
import type { TBizIdent } from '@/types/alias';
import { encodeBase64, tDate } from 'shangshi_core';
import axios from 'axios';
import { KEY_USER_CACHE, KEY_MENU_CACHE, KEY_EXPIRED, KEY_TOKEN, KEY_CHINA_CITYS } from '@/constant/sessionKey';
import { Toaster } from '@/util/uiKit/toaster';
import { getCache, setCache } from '@/util/cacheKit/getCache';
import { empty } from 'shangshi_bizproto';
import { useLoadding } from '@/hook';
import { fmtUrl, HEADERS, PREFIX_BIZ, URL_TOKEN_LESS } from './remoteKit';

export const API_CACHE: Record<TApiDataType, AxiosInstance | null> = {
  data: null,
  file: null,
  static: null,
  worker: null
};

// #region Base
/** 请求拦截器 */
const guardReq = (dataType: TApiDataType) => {
  return async (conf: any): Promise<any> => {
    useLoadding().setLoadding(true);
    const _useToken_ = (conf.url && !(URL_TOKEN_LESS.includes(conf.url))) || false;
    if (_useToken_) {
      // const _token_ = JSON.parse(sessionStorage.getItem(KEY_USER_CACHE) || '{}').token || '';
      const _token_ = sessionStorage.getItem(KEY_TOKEN) || '';
      if (_token_) {
        // @ts-ignore
        HEADERS['x-access-token'] = _token_;
      }
    }
    // @ts-ignore
    HEADERS['Content-Type'] = (dataType === 'file' && 'multipart/form-data') || 'application/json';
    if (dataType === 'file') {
      HEADERS['Cache-Control'] = 'no-cache';
      HEADERS['Pragma'] = 'no-cache';
    }
    conf.headers = HEADERS;
    return conf;
  };
};

/** 拦截异常处理器 */
const errHandler = (err: Error) => {
  // @ts-ignore
  Toaster.error((err.code === 'ERR_BAD_RESPONSE' && '服务连接失败，请检查服务端或网络状态') || err.message);
  console.error(err);
  useLoadding().setLoadding(false);
  return Promise.reject(err);
};

/** 响应拦截器 */
const guardRes = async (res: any): Promise<any> => {
  try {
    const resObj: IResData = structuredClone(res.data);
    useLoadding().setLoadding(false);
    if (resObj.isErr) {
      Toaster.error(resObj.msg);
      // return null;
      throw new Error(resObj.msg);
    } else {
      return resObj.data || null;
    }
  } catch (err) {
    console.error('拦截器异常');
    console.error(err);
    throw err;
  }
};

/** 获取axios共享实例 */
const getApi = (dataType: TApiDataType = 'data'): AxiosInstance => {
  const urlFrom = window.location.origin;
  const isNotRemote = /:\d+/.test(urlFrom);
  const preRouter = dataType === 'data' ? (isNotRemote ? `${PREFIX_BIZ}/` : `${PREFIX_BIZ}/${PREFIX_BIZ}/`) : `${dataType}/`;
  const baseURL = /:\d+/.test(urlFrom) ? `${urlFrom}/${preRouter}` : `${urlFrom}/${preRouter}`;
  if (API_CACHE[dataType]) {
    return API_CACHE[dataType]!;
  } else {
    const API: AxiosInstance = axios.create({
      baseURL,
      decompress: true
    });

    /** 请求拦截 */
    API.interceptors.request.use(guardReq(dataType), errHandler);

    /** 响应拦截 */
    API.interceptors.response.use(guardRes, errHandler);
    API_CACHE[dataType] = API;
    return API;
  }
};

/** 心跳检测 */
export const apiHeartBit = async () => {
  return await getApi().get(fmtUrl('/getHeartBit'));
};
// #endregion

// #region 主数据
/** 列表请求
 *
 * @param opt 列表查询条件
 * @param url 列表查询请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiGetListMaster = async <T extends IOrmBase = IOrmBase>(opt: Record<string, any> = {}, url?: string): Promise<T[]> => {
  const urlStr = url ? fmtUrl(url) : fmtUrl('/getListMaster');
  const list = await getApi().get(urlStr, {
    params: opt
  }) as T[];
  return list;
};

/** 对象查询
 *
 * @param bizIdent 要执行对象查询操作的业务标识
 * @param id 要查询的数据记录的ID值
 * @param url 查询请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiGetObjMaster = async <T extends IOrmBase = IOrmBase>(bizIdent: string, id: string | number, url?: string, preTreeKey?: string): Promise<Record<string, T>> => {
  const obj: Record<string, any> = await getApi().get(fmtUrl(url || '/getObjMaster', bizIdent, `${id}`), { params: { preTreeKey } });
  if (!obj[bizIdent]) throw new Error('未查询到相关数据，或后端返回了无效结果');
  return obj;
};

/** 发起主数据数据新增操作
 *
 * @param payload 数据载荷
 * @param url 新增操作的请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiPostMaster = async <T extends IOrmBase = IOrmBase>(payload: Partial<T>, bizIdent: string, opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string }): Promise<IResData> => {
  const optPayload: { payload: Partial<T>, fromPush?: boolean, pushDef?: Record<string, any> } = { payload, fromPush: opt?.fromPush, pushDef: opt?.pushDef };
  if (opt?.pushDef) {
    optPayload.pushDef = opt.pushDef;
  }
  const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/postMaster', bizIdent);
  return await getApi().post(url, optPayload);
};

/** 发起主数据数据修改操作
 *
 * @param payload 数据载荷
 * @param url 修改操作的请求地址，如果不提供，则调用全局修改路由
 * @returns
 */
export const apiPutMaster = async <T extends IOrmBase = IOrmBase>(payload: Partial<T>, bizIdent: string, opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string }): Promise<IResData> => {
  const optPayload: { payload: Partial<T>, fromPush?: boolean, pushDef?: Record<string, any> } = { payload, fromPush: opt?.fromPush, pushDef: opt?.pushDef };
  if (opt?.pushDef) {
    optPayload.pushDef = opt.pushDef;
  }
  const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/putMaster', bizIdent);
  return await getApi().put(url, optPayload);
};

/** 发起主数据删除请求
 *
 * @param bizIdent 要执行删除操作的业务标识
 * @param tobeDelIds 要删除的记录ID集合
 * @returns
 */
export const apiDelMaster = async (bizIdent: string, tobeDelIds: string | string[] | number | number[], opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string }): Promise<void> => {
  const ids: string = Array.isArray(tobeDelIds) ? tobeDelIds.join(',') : `${tobeDelIds}`;
  if (ids) {
    const optPayload: { fromPush?: boolean, pushDef?: Record<string, any> } = { fromPush: opt?.fromPush, pushDef: opt?.pushDef };
    if (opt?.pushDef) {
      optPayload.pushDef = opt.pushDef;
    }
    const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/delMaster', bizIdent);
    return await getApi().delete(url, {
      params: { ids, ...optPayload }
    });
  } else {
    throw new Error('必须为删除操作指定目标ID');
  }
};

/** 发起主数据停用请求
 *
 * @param bizIdent 要执行停用操作的业务标识
 * @param tobeStopIds 要停用的记录ID集合
 * @returns
 */
export const apiStopMaster = async (bizIdent: string, tobeStopIds: string | string[] | number | number[], url?: string): Promise<void> => {
  const ids: string = Array.isArray(tobeStopIds) ? tobeStopIds.join(',') : `${tobeStopIds}`;
  if (ids) {
    return await getApi().put(fmtUrl(url || '/stopObj', bizIdent), {
      ids
    });
  } else {
    throw new Error('必须为停用操作指定目标ID');
  }
};

/** 获取主数据的一览树
 *
 * @param bizIdentMaster 主数据自身的业务标识
 * @param selectKey 在多表共同形成一个完整树状时，数据表用于关联层级表所使用的字段（如 master_product_mgr 通过 categoryId 关联 master_prod_category 表，则该参数即为 categoryId
 * @param bizIdentTree 主数据的从属业务标识，该标识为主数据构造树形结构。如物料主数据的“物料属性”，将物料数据按照属性树进行挂载
 * @returns
 */
export const apiGetMasterTree = async <T extends ITreeItem = ITreeItem>(bizIdentMaster: string, selectKey: string, bizIdentTree?: string): Promise<T[]> => {
  const list = await getApi().get(fmtUrl(`/getMasterAsTree/${bizIdentMaster}/${selectKey}/${bizIdentTree || '-'}`), {}) as T[];
  return list;
};

/** 发起下拉数据源的请求操作
 *
 * @param bizKeys 要请求的主数据的业务标识集合
 * @description 支持 foo、[foo, bar]、foo,bar 等多种形式
 * @returns
 */
export const apiGetKvSource = async (dictKeys: string[], masterKeys: string[]): Promise<Record<string, IOrmKvDict[]>> => {
  const params = {
    dictKeys: dictKeys.join(','),
    masterKeys: masterKeys.join(',')
  };
  return await getApi().get('/getKvSource', {
    params
  }) as Record<string, IOrmKvDict[]> || {};
};

/** 设置工程客户为已提成
 *
 * @param counterId 要设置的工程客户ID
 */
export const apiSetCommission = async (counterId: number, data?: string | null): Promise<{ dateCommission: string }> => {
  try {
    return await getApi().put(fmtUrl('/setCommission', `${counterId}`), { dateCommission: data });
  } catch (err) {
    console.error(err);
    Toaster.error('提成状态设置失败');
    throw err;
  }
};

/** 根据工程获取客户
 *
 * @param projId 工程ID
 * @returns
 */
export const apiGetCustByProj = async (projId?: number): Promise<{ custId: number, custIdNamezh: string }> => {
  return await getApi().get(fmtUrl(`/getCustByProj/${projId}`), {}) as { custId: number, custIdNamezh: string };
};

/** 获取待导出的主数据
 *
 * @param bizIdent 主数据业务标识
 * @returns
 */
export const apiGetExportMaster = async (bizIdent: string): Promise<Record<string, any>[]> => {
  return await getApi().get(fmtUrl(`/getExportMaster/${bizIdent}`), {});
};

/** 获取往来户的一览树
 *
 * @returns
 */
export const apiGetCounterTree = async (): Promise<IKvTreeRes> => {
  const list = await getApi().get(fmtUrl('/getKvTreeCounter'), {}) as IKvTreeRes;
  return list;
};

/** 获取物料的一览树
 *
 * @returns
 */
export const apiGetProductTree = async (): Promise<{ treeList: unknown[] }> => {
  const list = await getApi().get(fmtUrl('/getKvTreeProduct'), {}) as { treeList: unknown[] };
  return list;
};

/** 获取物料的扁平备选列表
 *
 * @returns
 */
export const apiGetProductList = async (): Promise<unknown[]> => {
  return (await getApi().get(fmtUrl('/getKvListProduct'), {})) as unknown[];
};

/** 为物料粘贴获取扁平备选列表
 *
 * @returns
 */
export const apiGetProductPasteSource = async (keys: (string | number)[]): Promise<Record<string, Record<string, any>[]>> => {
  return (await getApi().get(fmtUrl('/getProductPasteSource'), { params: { pasteKeys: keys.join(',') } })) as Record<string, Record<string, any>[]>;
};

/** 获取仓库的一览树
 *
 * @returns
 */
export const apiGetStoreTree = async (): Promise<IKvTreeRes> => {
  const list = await getApi().get(fmtUrl('/getKvTreeStore'), {}) as IKvTreeRes;
  return list;
};

/** 获取指定往来户的指定物料的价格列表
 *
 * @param counterId 往来户ID
 * @returns
 */
export const apiGetCounterPriceByProduct = async (prodId: number): Promise<TPayload[]> => {
  const list = await getApi().get(fmtUrl('/getCounterPriceByProduct', `${prodId}`), {}) as TPayload[];
  return list;
};

/** 异步获取中国省份城市区县数据 */
export const apiInitChinaCitys = async () => {
  const arr = (await getCache('kvSource', KEY_CHINA_CITYS) || []) as IMasterTree[];
  if (!arr?.length) {
    // const objData = await getApi('static').get('/data/chinaCityFull.json') as IMasterTree[] || [];
    const objData = await getApi('static').get('/data/chinaCitys.json') as IMasterTree[] || [];
    await setCache('kvSource', KEY_CHINA_CITYS, objData);
  }
  arr.splice(0, arr.length);
};
// #endregion

// #region 报表
/** 报表数据查询请求
 *
 * @param rptName 要查询数据的报表标识
 * @param filter 报表查询条件
 * @returns
 */
export const apiGetReport = async (rptName: string, filter: Record<string, any> = {}): Promise<unknown[]> => {
  return await getApi().get(fmtUrl(`/getReport/${rptName}`), {
    params: { filter: JSON.stringify(filter) }
  }) || [];
};

/** 交叉转置报表数据查询请求
 *
 * @param rptName 要查询数据的报表标识
 * @param opt 报表查询条件
 * @returns
 */
export const apiGetCrossReport = async (rptName: string, filter: Record<string, any> = {}, preHeader?: boolean): Promise<unknown[]> => {
  const params: {
    filter: string,
    preHeader?: boolean
  } = {
    filter: JSON.stringify(filter)
  };
  if (preHeader) {
    params.preHeader = true;
  }
  return await getApi().get(fmtUrl(`/getCrossReport/${rptName}`), { params }) || [];
};
// #endregion

// #region 设置项
/** 获取设置面板的系统参数 */
export const apiGetBizParam = async (): Promise<ISysBizParam> => {
  return (await getApi().get('/getGlobBizParam/', {})) as ISysBizParam;
};

/** 设置项的列表请求
 *
 * @param bizIdent 设置项的业务标识
 * @returns
 */
export const apiGetListSetting = async <T extends IOrmBase = IOrmBase>(bizIdent: string): Promise<T[]> => {
  const list = await getApi().get(fmtUrl('/getListSetting', bizIdent), {}) as T[];
  return list;
};

/** 对象查询
 *
 * @param bizIdent 要执行对象查询操作的业务标识
 * @param id 要查询的数据记录的ID值
 * @returns
 */
export const apiGetObjSetting = async <T extends IOrmBase = IOrmBase>(bizIdent: string, id: string | number): Promise<Record<string, T>> => {
  const obj: Record<string, any> = await getApi().get(fmtUrl('/getObjSetting', bizIdent, `${id}`), {});
  if (!obj[bizIdent]) throw new Error('未查询到相关数据，或后端返回了无效结果');
  return obj;
};

/** 发起主数据数据新增操作
 *
 * @param bizIdent 系统设置项的业务标识
 * @param payload 数据载荷
 * @returns
 */
export const apiPostSetting = async <T extends IOrmBase = IOrmBase>(bizIdent: string, payload: Partial<T>): Promise<IResData> => {
  return await getApi().post(fmtUrl('/postSetting', bizIdent), payload);
};

/** 发起主数据数据修改操作
 *
 * @param bizIdent 系统设置项的业务标识
 * @param payload 数据载荷
 * @returns
 */
export const apiPutSetting = async <T extends IOrmBase = IOrmBase>(bizIdent: string, payload: Partial<T>): Promise<IResData> => {
  return await getApi().put(fmtUrl('/putSetting', bizIdent), payload);
};
// #endregion

// #region 表单 CRUD
/** 列表请求
 *
 * @param opt 列表查询条件
 * @param url 列表查询请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiGetListBill = async <T extends IOrmBase = IOrmBase>(opt: Record<string, any> = {}, url?: string): Promise<T[]> => {
  const urlStr = url ? fmtUrl(url) : fmtUrl('/getListBill');
  const list = await getApi().get(urlStr, {
    params: opt
  }) as T[];
  return list;
};

/** 发起面向单据的对象查询
 *
 * @param bizIdent 要执行对象查询操作的业务标识
 * @param id 要查询的数据记录的ID值
 * @param url 查询请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiGetObjBill = async <T extends IOrmBase = IOrmBase>(bizIdent: string, id: string | number, url?: string, preTreeKey?: string): Promise<Record<string, T>> => {
  const obj: Record<string, any> = await getApi().get(fmtUrl(url || '/getObjBill', bizIdent, `${id}`), { params: { preTreeKey } });
  if (!obj[bizIdent]) throw new Error('未查询到相关数据，或后端返回了无效结果');
  return obj;
};

/** 发起表单数据新增操作
 *
 * @param payload 数据载荷
 * @param url 新增操作的请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiPostBill = async (payload: TEditPayload, bizIdent: string, opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string, appendData?: Record<TBizIdent, TPayload[]> }): Promise<{ billId: string | number }> => {
  const optPayload: { payload: TEditPayload, fromPush?: boolean, pushDef?: Record<string, any>, appendData?: Record<TBizIdent, TPayload[]> } = { payload, fromPush: opt?.fromPush, pushDef: opt?.pushDef };
  if (opt?.pushDef) {
    optPayload.pushDef = opt.pushDef;
  }
  if (opt?.appendData) {
    optPayload.appendData = opt.appendData;
  }
  const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/postBill', bizIdent);
  // @ts-ignore
  optPayload.opt = opt;
  return await getApi().post(url, optPayload);
};

/** 发起表单数据修改操作
 *
 * @param payload 数据载荷
 * @param url 修改操作的请求地址，如果不提供，则调用全局修改路由
 * @returns
 */
export const apiPutBill = async (payload: TEditPayload, bizIdent: string, opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string, appendData?: Record<TBizIdent, TPayload[]> }): Promise<IResData> => {
  const optPayload: { payload: TEditPayload, fromPush?: boolean, pushDef?: Record<string, any>, appendData?: Record<TBizIdent, TPayload[]> } = { payload, fromPush: opt?.fromPush, pushDef: opt?.pushDef };
  if (opt?.pushDef) {
    optPayload.pushDef = opt.pushDef;
  }
  if (opt?.appendData) {
    optPayload.appendData = opt.appendData;
  }
  const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/putBill', bizIdent);
  return await getApi().put(url, optPayload);
};

/** 发起表单删除请求
 *
 * @param bizIdent 要执行删除操作的业务标识
 * @param tobeDelIds 要删除的记录ID集合
 * @returns
 */
export const apiDelBill = async (bizIdent: string, tobeDelIds: string | string[] | number | number[], opt?: { fromPush?: boolean, pushDef?: Record<string, any>, url?: string }): Promise<void> => {
  const ids: string = Array.isArray(tobeDelIds) ? tobeDelIds.join(',') : `${tobeDelIds}`;
  if (ids) {
    const optPayload: { fromPush?: boolean, pushDef?: Record<string, any> } = { fromPush: opt?.fromPush, pushDef: opt?.pushDef };
    if (opt?.pushDef) {
      optPayload.pushDef = opt.pushDef;
    }
    const url = opt?.url ? fmtUrl(opt.url) : fmtUrl('/delBill', bizIdent);
    return await getApi().delete(url, {
      params: { ids, ...optPayload }
    });
  } else {
    throw new Error('必须为删除操作指定目标ID');
  }
};

/** 发起单据审批请求 */
export const apiApprovBill = async (payload: { bizIdent: string, billId: number | string, comments: string, val: boolean }): Promise<{ approStep?: number }> => {
  return (await getApi().put(fmtUrl('/approvBill'), payload)) as { approStep?: number } || {};
};

/** 预加载待克隆的物料信息
 *
 * @param fromId 要克隆物料的 id
 * @returns
 */
export const apiPreCloneProd = async (fromId: number): Promise<Record<string, unknown>> => {
  return await getApi().get(fmtUrl('/preCloneProd', `${fromId}`));
};

/** 获取业务痕迹
 *
 * @param billId 业务对应的单据ID
 * @param bizIdent 业务标识
 * @returns
 */
export const apiGetBillHistory = async (billId: number, bizIdent: string): Promise<IHisRes> => {
  const emptyObj: IHisRes = { history: [], profile: empty.EMPTY_BILL_PROFILE() };
  try {
    return (await getApi().get(fmtUrl(`/getHistory/${bizIdent}/${billId}`), {}) || emptyObj) as IHisRes;
  } catch (err) {
    return emptyObj;
  }
};

/** 获取上引下推的mapView
 *
 * @param billId 业务对应的单据ID
 * @param bizIdent 业务标识
 * @returns
 */
export const apiGetMapView = async (billId: number, bizIdent: string): Promise<Record<string, unknown>> => {
  const emptyObj: IHisRes = { history: [], profile: empty.EMPTY_BILL_PROFILE() };
  try {
    return (await getApi().get(fmtUrl(`/getMapView/${bizIdent}/${billId}`), {}) || emptyObj) as Record<string, unknown>;
  } catch (err) {
    return {};
  }
};

/** 单据下推数据准备
 *
 * @param pushParam 下推定义
 * @returns
 */
export const apiPrePush = async (ids: number[], bizIdentFrom: string, bizIdenTo: string, bizIdentDtlFrom: string, bizIdentDtlTo: string, fromPull?: boolean): Promise<any> => {
  if (!ids.length) throw new Error('下推数据源不能为空');
  const opt: Record<string, any> = {};
  if (fromPull) {
    opt.fromPull = true;
  }
  return await getApi().post(fmtUrl(`/getPushSource/${bizIdentFrom}/${bizIdentDtlFrom}/${bizIdenTo}/${bizIdentDtlTo}/${ids.join(',')}`), opt);
};

/** 仓库出入库单据输入通知单号加载数据
 *
 * @param billNo 通知单号
 * @param direction 出入库单据类型
 * @returns
 */
export const apiGetStoreBiloByNotice = async (bizIdentFrom: string, bizIdentDtlFrom: string, bizIdentTo: string, bizIdentDtlTo: string, opt: IPrePushNoticeParam): Promise<any> => {
  const resObj = await getApi().post(fmtUrl(`/getStoreBiloByNotice/${bizIdentFrom}/${bizIdentDtlFrom}/${bizIdentTo}/${bizIdentDtlTo}`), opt) as any;
  if (resObj.errMsg) {
    Toaster.error(resObj.errMsg);
    throw new Error(resObj.errMsg);
  } else {
    return resObj;
  }
};

/** 检查对象是否需要超限审批
 *
 * @param bizIdent 要检查超审的的业务标识
 * @param id 要检查超审的单据ID值
 * @param url 查询请求地址，如果不提供，则调用全局默认路由
 * @returns
 */
export const apiCheckSuperApprove = async (bizIdent: string, payload: Record<string, any>, url?: string): Promise<{ canAppro: boolean, info: { prod: string, priceNow: number, prePrice: number }[] }> => {
  return await getApi().get(fmtUrl(url || '/checkSuperAppro', bizIdent), { params: payload }) as { canAppro: boolean, info: { prod: string, priceNow: number, prePrice: number }[] } || { canAppro: false, info: [] };
};

/** 检验超审密码
 *
 * @param uPwd 超审密码
 */
export const checkSuperApprovePwd = async (uPwd: string): Promise<{ checked: boolean }> => {
  const decodePwd = encodeBase64(JSON.stringify({ uPwd }));
  const resObj = await getApi().get('/checkSuperApprovePwd', { params: { payload: decodePwd } }) as { checked: boolean };
  if (resObj.checked) {
    return resObj;
  } else {
    throw new Error('密码校验失败');
  }
};

/** 获取指定客户的收款记录
 *
 * @param custId 客户ID
 * @param billId 通知单Id
 * @param queryType 查询类型， notIn: 不在本通知单范围内的（作为备选数据），in: 已被标记为本通知单收款的数据
 * @returns
 */
export const apiGetReceiptByNotice = async (custId: number, billId: number, queryType: 'notIn' | 'in' = 'in'): Promise<Record<string, any>[]> => {
  return await getApi().get(fmtUrl(`/getReceiptByNotice/${custId}/${billId}/${queryType}`), {});
};

/** 为指定的销售通知单添加指定客户的收款记录
 *
 * @param billId 通知单Id
 * @param receiptIds 收款单ID集合
 * @returns
 */
export const apiBindReceiptByNotice = async (billId: number, receiptIds: number[]): Promise<Record<string, any>[]> => {
  return await getApi().post(fmtUrl('/bindReceiptByNotice'), { billId, receiptIds });
};
// #endregion

// #region 登录及账户
/** 发起登录请求
 *
 * @param uName 登录用户名
 * @param uPwd 登录密码
 */
export const apiSignIn = async (uName: string, uPwd: string): Promise<IUserSingnInfo & { menus: IMenuItem[] }> => {
  sessionStorage.clear();
  const paramObj = encodeBase64(JSON.stringify({ uName, uPwd }));
  const resSignData = await getApi().get('/sign/in', { params: { payload: paramObj } }) as ISignRes;
  const { menus = [], expired, token, userInfo } = resSignData;
  sessionStorage.setItem(KEY_MENU_CACHE, JSON.stringify(menus));
  sessionStorage.setItem(KEY_USER_CACHE, JSON.stringify(userInfo));
  sessionStorage.setItem(KEY_EXPIRED, `${expired}`);
  sessionStorage.setItem(KEY_TOKEN, token);
  const userData = userInfo as unknown;
  if (userData) {
    return { ...userData, menus } as IUserSingnInfo & { menus: IMenuItem[] };
  } else {
    throw new Error('登录失败');
  }
};

/** 登录用户注销 */
export const apiSignOut = (): void => {
  const uName = JSON.parse(sessionStorage.getItem(KEY_USER_CACHE) || '{}')?.code;
  const paramObj = encodeBase64(JSON.stringify({ uName }));
  getApi().get('/sign/out', { params: { payload: paramObj } });
  sessionStorage.clear();
  // window.location.href = '/sign';
};

/** 重设用户密码
 *
 * @param pwdOld 原始密码
 * @param pwdNew 新密码
 */
export const apiChangPwd = async (pwdOld: string, pwdNew: string): Promise<{ changeOk?: boolean }> => {
  return await getApi().put('/userChangePwd', { data: encodeBase64(JSON.stringify({ pwdOld, pwdNew })) });
};
// #endregion

// #region 送货
/** 更改送货信息
 *
 * @param noticeCode 通知单编号
 * @param val 要更改的值
 * @param type 要更改的类型
 * @returns
 */
export const apiResetDeliveryInfo = async (noticeCode: string, val: any, type: string): Promise<void> => {
  return await getApi().put(fmtUrl(`/resetDeliveryInfo/${noticeCode}`), { type, val });
};

/** 送货信息更新查询请求
 *
 * @param opt 查询条件
 * @returns
 */
export const apiGetDeliveryList = async (opt: Record<string, any> = {}): Promise<any> => {
  return await getApi().get(fmtUrl('/getDeliveryList'), {
    params: opt
  });
};
// #endregion

// #region 人力资源
// 人事异动
export const apiUpEmployeeMove = async (moveType: TRemindTag, employeeId: number, dateVal: string, opt?: Record<string, any>): Promise<{ post: Record<string, any>[], remind: Record<string, any>[], birthDay: Record<string, any>[] }> => {
  if (!dateVal) throw new Error('员工人事异动日期不能为空');
  const obj: Record<string, string> = {};
  obj[moveType] = dateVal;
  const list = await getApi().put(fmtUrl('employeeMove', moveType, `${employeeId}`), Object.assign(obj, opt || {})) as unknown;
  return list as { post: Record<string, any>[], remind: Record<string, any>[], birthDay: Record<string, any>[] };
};
// #endregion

// #region 库存
/** 获取库存
 *
 * @param prodIds 以半角逗号分隔的要查询库存的物料ID的字符串，若查询全品库存，则用 @ 代替
 * @param selectKey 在多表共同形成一个完整树状时，数据表用于关联层级表所使用的字段（如 master_product_mgr 通过 categoryId 关联 master_prod_category 表，则该参数即为 categoryId
 * @param bizIdentTree 主数据的从属业务标识，该标识为主数据构造树形结构。如物料主数据的“物料属性”，将物料数据按照属性树进行挂载
 * @returns
 */
export const apiGetDaliyStore = async (prodIds: string | '@'): Promise<IStoryDaliyRes> => { // queryRange: biz.store.TInventoryRange = 'daliy'
  const obj = await getApi().get(fmtUrl(`/getInventory/${prodIds}`), {}) as IStoryDaliyRes;
  return obj;
};
// #endregion

// #region 返利
/** 获取指定客户端返利记录 */
export const apiGetSaleRebat = async (opt: Record<string, any> = {}): Promise<Record<string, any>[]> => {
  const counterparty = +(opt?.counterparty || -1);
  if (counterparty > 0) {
    return await getApi().get(fmtUrl('/getAvailableRebates', counterparty), {
      params: opt
    });
  } else {
    return [];
  }
};
// #endregion

// #region 财务核销
export const apiGetFiClearance = async (clearanceType: TClearanceType, filter: Record<string, any>): Promise<IClearancePayload> => {
  return await getApi().get(fmtUrl('/getFiClearance', clearanceType), { params: { filter: JSON.stringify(filter) } });
};

/** 执行核销
 *
 * @param clearanceType 核销类型： 收款/付款
 * @param optType 核销方式： 自动 / 手动
 * @param custId 客户ID
 * @returns
 */
export const apiExecFiClearance = async (clearanceType: TClearanceType, optType: 'auto' | 'manual' | 'cancel', custId: number, param?: { invc: IClearance, bill: IClearance[] }): Promise<void> => {
  if (optType === 'auto') {
    await getApi().get(fmtUrl('/execClearanceAuto', clearanceType, `${custId}`), {});
  } else if (optType === 'manual') {
    const { invc, bill } = param || {};
    if (invc && bill?.length) {
      await getApi().post(fmtUrl('/execClearanceManual'), { invc, bill });
    } else {
      Toaster.error('未提供有效的核心数据');
    }
  } else {
    const { invc, bill } = param || {};
    if (invc && bill?.length) {
      await getApi().post(fmtUrl('/execClearanceCancel'), { invc, bill });
    } else {
      Toaster.error('未提供有效的核心数据');
    }
  }
};

/** 取消核销
 *
 * @param clearanceType 核销类型： 收款/付款
 * @param hisIds 核销记录ID集合
 * @returns
 */
export const apiCancelClearance = async (hisIds: number[]): Promise<void> => {
  if (!hisIds.length) {
    Toaster.error('未选择任何待取消核销的记录');
  } else {
    const url = fmtUrl('/cancelClearance');
    const opt = { hisIds: hisIds.join(',') };
    return await getApi().put(url, opt);
  }
};
// #endregion

// #region 成本核算
/** 获取成本核算信息
 *
 * @param opt 查询条件
 * @returns
 */
export const apiCostRollover = async (opt?: Record<string, any>): Promise<{ list: any[], profile: ICostProfile }> => {
  return await getApi().get(fmtUrl('/bill/costAccounting/costRollover/getList'), {
    params: { filter: JSON.stringify(opt || {}) }
  }) as { list: any[], profile: ICostProfile };
};

/** 成本核算调整
 *
 * @param opt 查询条件
 * @returns
 */
export const apiCostRolloverAdjust = async (dateAcc: string, key: string, list: any[]): Promise<{ list: any[], profile: ICostProfile }> => {
  return await getApi().put(fmtUrl('/bill/costAccounting/costRollover/execEdit'), { dateAcc, key, list });
};

/** 成本结转
 *
 * @param dateAcc 会计期
 * @param unDo 本次操作是否为取消结转
 * @returns
 */
export const apiCostCarryover = async (dateAcc: string, unDo?: boolean): Promise<{ list: any[], profile: ICostProfile }> => {
  const opt: { dateAcc: string, unDo?: 1 | 0 } = { dateAcc };
  if (unDo) {
    opt.unDo = 1;
  }
  return await getApi().put(fmtUrl('/bill//costAccounting/costCarryover/execDone'), opt);
};
// #endregion

// #region 调价
/** 预加载调价资料
 *
 * @param billNo 通知单号
 * @param direction 出入库单据类型
 * @returns
 */
export const apiPrePriceAdjust = async (): Promise<any> => {
  return await getApi().get(fmtUrl('/prePriceAdjustKv'), {});
};

/** 获取待调价任务列表
 *
 * @param billNo 通知单号
 * @param direction 出入库单据类型
 * @returns
 */
export const apiGetAdjustTask = async (categoryIds: string[], payload: Record<string, any>): Promise<any> => {
  return await getApi().get(fmtUrl('/getAdjustTask'), { params: { prodCategoryId: categoryIds.join(','), priceTypeId1: payload.priceTypeId1, priceTypeId2: payload.priceTypeId2 } });
};

/** 应用调价
 *
 * @param payload 待调价的数据载荷
 * @param effectType 调价方式： now: 立即调价, plan: 定时调价
 * @returns
 */
export const adjustPrice = async (payload: Record<string, any>[], range: string[], effectType: 'now' | 'plan'): Promise<any> => {
  return await getApi().post(fmtUrl(`/priceAdjust/${effectType}`), { payload, range });
};
// #endregion

// #region 待办提醒
/** 追加提醒事项 */
export const apiPostRemind = async (payload: IRemind, bizIdent: string, billId: number): Promise<void> => {
  await getApi().post(fmtUrl('/postRemind', bizIdent, `${billId}`), payload);
};
// #endregion

// #region 文件操作
/** 发送EXCEL数据到后端
 *
 * @param payload 数据载荷
 * @param handlerName 数据处理函数名称
 * @param opt { threshold?: 单次发送数据行数阀值 }
 * @returns
 */
export const apiPostExcel = async (payload: any[][], handlerName: string, opt?: { threshold?: number }): Promise<void> => {
  const threshold = opt?.threshold || 10;  // 单次发送数据行数阀值
  const obj: Map<number, any[][]> = new Map();
  let idx = 1;
  while (payload.length) {
    obj.set(idx, payload.splice(0, threshold));
    idx++;
  }
  const arr = obj.values();
  const taskId = tDate().format('yyyymmddhhmissms');

  idx = 0;
  const times = obj.size;  // 需要循环发送的片段数
  try {
    for (const v of arr) {
      const resData = await getApi().post(fmtUrl('/postExcel', taskId, handlerName), { payload: v, idx, times }) as string;
      Toaster.success(resData);
      idx++;
    }
    Toaster.warning('数据处理中...');
    const resImport = await getApi().post(fmtUrl('/postExcel', taskId, handlerName), { loadDone: true }) as string;
    console.log(resImport);
    Toaster.success('导入结束');
  } catch (err) {
    await getApi().post(fmtUrl('/postExcel', taskId, handlerName), { cancel: true });
  }
};
// #endregion

// #region 权限
/** 获取用户列表
 * @param opt 数据查询条件
 * @returns
 */
export const getUserList = async (opt?: Record<string, any>): Promise<any> => {
  return await getApi().get(fmtUrl('/getUserList'), { params: opt || {} });
};

/** 获取用户中心数据列表
 * @param opt 数据查询条件
 * @returns
 */
export const getUserCenterList = async (opt?: Record<string, any>): Promise<any> => {
  return await getApi().get(fmtUrl('/getUserCenterList'), { params: opt || {} });
};

/** 获取角色列表
 * @param opt 数据查询条件
 * @returns
 */
export const getGoupList = async (opt?: Record<string, any>): Promise<any> => {
  return await getApi().get(fmtUrl('/getRoleList'), { params: opt || {} });
};

/** 用户中心管理功能
 *
 * @param payload 数据载荷
 * @param type 操作类型
 * @returns
 */
export const apiUserAccMgr = async (payload: Record<string, any>, type: TUserAccMgrType): Promise<Record<string, any>[]> => {
  return await getApi().put(fmtUrl('/mgrUserAccount', type), { payload });
};

/** 角色管理功能
 *
 * @param payload 数据载荷
 * @param type 操作类型
 * @returns
 */
export const apiRoleMgr = async (payload: Record<string, any>, type: TRoleMgrType): Promise<Record<string, any>[]> => {
  return await getApi().put(fmtUrl('/mgrRole', type), { payload });
};

/** 初始化权限面板
 *
 * @returns
 */
export const apiInitPermissionCenter = async (): Promise<IAuthSource> => {
  return await getApi().get('getAuthPanel', {});
};

/** 获取指定角色的
 *
 * @param roleId 目标角色ID
 * @returns
 */
export const apiGetPermission = async (roleId: number): Promise<IAuthRes> => {
  return await getApi().get(fmtUrl('/getPermission', `${roleId}`), {}) as IAuthRes;
};

/** 写入授权信息
 *
 * @param payload 数据载荷
 * @returns
 */
export const apiSetPermission = async (payload: IPermissonPayload): Promise<void> => {
  return await getApi().post(fmtUrl('/setPermission'), payload);
};

/** 读取指定用户的角色列表
 *
 * @param userId 用户ID
 * @returns
 */
export const apiGetRoleByUserId = async (userId: number): Promise<IIdNamezh[]> => {
  return await getApi().get(fmtUrl('/getRoleByUserId', `${userId}`), {}) as IIdNamezh[];
};

/** 读取指定角色的用户列表
 *
 * @param roleId 角色ID
 * @returns
 */
export const apiGetUserByRoleId = async (roleId: number): Promise<IIdNamezh[]> => {
  return await getApi().get(fmtUrl('/getUserByRoleId', `${roleId}`), {}) as IIdNamezh[];
};

/** 从指定的角色中移除用户，或对指定用户移除指定角色
 *
 * @param roleId 角色ID
 * @returns
 */
export const apiRmUserFromRole = async (roleId: number, userId: number): Promise<IIdNamezh[]> => {
  return await getApi().post(fmtUrl('/rmUserFromRole'), { roleId, userId }) as IIdNamezh[];
};
// #endregion
