<template>
  <div class="tabRow" :class="{ px3: showAnchors }">
    <div ref="tabWrapperRef" class="tabWrapper flexY">
      <span v-show="showAnchors" class="scrollAnchor left py2" @click="scrollTabWrapper('left')">
        <IconArrowLeft></IconArrowLeft>
      </span>
      <div class="tabItemsContainer flexY flexSplit">
        <template v-for="item of openPages" :key="item.name">
          <span class="tabItem flexXY noSelect fontSm" :class="{ active: activeKey === item.keyTo }">
            <span class="flexSplit tblContext" :class="{ 'pl2 pr1': item.keyTo !== HOME_KEY, px2: item.keyTo === HOME_KEY }" @click="switchTab(item)">{{ item?.namezh }}</span>
            <span v-if="item.keyTo !== HOME_KEY" class="closeTag mr1" @click="removeTab('only', { key: item.keyTo })">
              <IconClose></IconClose>
            </span>
          </span>
        </template>
      </div>
      <span v-show="showAnchors" class="scrollAnchor right py2" @click="scrollTabWrapper('right')">
        <IconArrowLeft></IconArrowLeft>
      </span>
    </div>
    <a-dropdown-button v-show="openPages.length" size="mini">
      <span @click="removeTab('all')">关闭所有标签</span>
      <template #icon>
        <icon-down />
      </template>
      <template #content>
        <a-doption @click="removeTab('except')">仅保留当前标签</a-doption>
        <a-doption @click="removeTab('left')">关闭当前标签左侧所有</a-doption>
        <a-doption @click="removeTab('right')">关闭当前标签右侧所有</a-doption>
      </template>
    </a-dropdown-button>
  </div>
</template>

<script setup lang="ts">
import type { TCloseDirction } from '@/types/store/useApp';
import type { IPageProfile, IRouterParam } from '@/types/hook/useNav';
import { HOME_KEY } from '@/constant/sessionKey';
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useElementSize } from '@vueuse/core';
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';
import IconClose from '@/components/icons/IconClose.vue';

const props = withDefaults(defineProps<{
  activeKey: string,
  openPages: IPageProfile[]
}>(), {
  openPages: () => []
});

const emit = defineEmits<{
  (e: 'onSwitchPage', val: string): void,
  (e: 'onRemoveTag', val: { type: TCloseDirction, key?: string, actParams?: IRouterParam }): void,
}>();

const tabWrapperRef = ref();
const showLeftAnchor = ref(false);
const showRightAnchor = ref(false);
const tabItemRefs = ref<any[]>([]);

const { width: widthWrapper } = useElementSize(tabWrapperRef);
const showAnchors = computed(() => {
  const obj = { wWrapper: widthWrapper.value, wCurr: 0 };
  for (const v of tabItemRefs.value) {
    obj.wCurr += ((v as HTMLElement)?.clientWidth || 0) + 38;
    if (obj.wCurr > obj.wWrapper) {
      break;
    }
  }
  return obj.wCurr > obj.wWrapper;
});

// const pageTagList = computed(() => Array.from(props.openPages.values()));

const switchTab = (item: IPageProfile) => {
  emit('onSwitchPage', item.keyTo as string);
};

const removeTab = (type: TCloseDirction, opt?: { key?: string }) => {
  const { key } = opt || {};
  const obj: { type: TCloseDirction, key?: string, actParams?: IRouterParam } = { key, type };
  const actKey = props.activeKey;
  const idx = props.openPages.findIndex(v => v.keyTo === actKey);
  const actParams = props.openPages[idx].params;
  if (actParams) obj.actParams = actParams;
  emit('onRemoveTag', obj);
};

const scrollTabWrapper = (direction: 'left' | 'right') => {
  if (tabWrapperRef.value) {
    const lastVisibleTabItem = tabItemRefs.value.find((tabItem) => {
      const rect = tabItem.getBoundingClientRect();
      return rect.right > tabWrapperRef.value!.offsetLeft && rect.left < tabWrapperRef.value!.offsetLeft + tabWrapperRef.value!.offsetWidth;
    });

    if (lastVisibleTabItem) {
      const lastVisibleTabItemIndex = tabItemRefs.value.indexOf(lastVisibleTabItem);
      const nextTabItem = direction === 'right' ? tabItemRefs.value[lastVisibleTabItemIndex + 1] : tabItemRefs.value[lastVisibleTabItemIndex - 1];

      if (nextTabItem) {
        const nextTabItemWidth = nextTabItem.offsetWidth;
        const scrollOffset = direction === 'right'
          ? lastVisibleTabItem.offsetLeft + lastVisibleTabItem.offsetWidth - tabWrapperRef.value!.offsetLeft + nextTabItemWidth
          : nextTabItem.offsetLeft - tabWrapperRef.value!.offsetLeft - tabWrapperRef.value!.offsetWidth;

        tabWrapperRef.value.scrollBy({ left: scrollOffset, behavior: 'smooth' });
      }
    }
  }
};

const handleScroll = () => {
  if (tabWrapperRef.value) {
    const { scrollLeft, scrollWidth, clientWidth } = tabWrapperRef.value;
    showLeftAnchor.value = scrollLeft > 0;
    showRightAnchor.value = scrollLeft + clientWidth < scrollWidth;
  }
};

onMounted(() => {
  if (tabWrapperRef.value) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          const lastVisibleTabItem = entry.target as HTMLSpanElement;
          const lastVisibleTabItemIndex = tabItemRefs.value.indexOf(lastVisibleTabItem);
          const nextTabItem = tabItemRefs.value[lastVisibleTabItemIndex + 1];
          if (nextTabItem) {
            scrollTabWrapper('right');
          }
        }
      });
    }, { root: tabWrapperRef.value as Element, threshold: 1 });

    tabWrapperRef.value.addEventListener('scroll', handleScroll);
    handleScroll();

    tabItemRefs.value.forEach((tabItem) => {
      observer.observe(tabItem);
    });
  }
});



onUnmounted(() => {
  if (tabWrapperRef.value) {
    tabWrapperRef.value.removeEventListener('scroll', handleScroll);
  }
});
</script>

<style lang="scss" scoped>
.tabRow {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 120px;
  // height: 32px;
  align-items: center;

  .tabWrapper {
    width: 100%;
    // height: 24px;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .scrollAnchor {
      width: 20px;
      height: 20px;
      background-color: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.left {
        position: absolute;
        left: 0;
        z-index: 1;
      }

      &.right {
        position: absolute;
        right: 0;
        z-index: 1;
        transform: rotateY(180deg);
      }

      &:hover {
        opacity: 1;
      }
    }

    .tabItem {
      position: relative;
      height: 22px;
      line-height: 22px;
      margin: 0 6px;
      // transition: all .2s cubic-bezier(0, 0, 1, 1);
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        border-radius: 32px;
        background-color: rgb(211, 212, 216);
      }

      .tblContext {
        height: 20px;
      }

      .closeTag {
        color: #293ac1;
        $closeTagHight: 18px;
        width: $closeTagHight;
        height: $closeTagHight;
        line-height: $closeTagHight;
        border-radius: 32px;
        font-size: 12px;
        text-align: center;

        &:hover {
          color: orangered;
          background-color: #ebebee;
        }
      }

      &.active {
        color: #293ac1;
        background-color: #bcb8cf;
        border-radius: 32px;
        box-shadow: inset 0 2px 4px 0 #3c3c3f;
      }
    }
  }
}
</style>
