import type { RouteRecordRaw } from 'vue-router';
import type { TCrudState } from 'shangshi_types';
import type { Component } from 'vue';
import type { IRouterParam, IRouterPushParams } from '@/types/hook/useNav';
import { upFirst } from 'shangshi_core';
import { lowerFirst } from '@/util/dataKit/lowerFirst';

const metaTag: Record<string, string> = {
  List: '列表',
  Add: '新增',
  Edit: '编辑',
  Obj: '查看'
};

const PAGE_ROOT = '../pages/bizHome/view/';
// @ts-ignore
const modules: Record<string, () => Component> = import.meta.glob([
  '../pages/bizHome/view/**/ViewAdd.vue',
  '../pages/bizHome/view/**/ViewEdit.vue',
  '../pages/bizHome/view/**/ViewList.vue',
  '../pages/bizHome/view/**/ViewObj.vue'
], { import: 'default' });

export const getSubRouters = (subMetas: Record<string, string[]>): RouteRecordRaw[] => {
  const arrAll: (RouteRecordRaw | null)[] = Object.entries(modules).map((v: [string, () => Component]): RouteRecordRaw | null => {
    const [filePath, component] = v;
    const arrPath = filePath.replace(PAGE_ROOT, '').split('/');
    const group = arrPath.shift();
    const fileType = (arrPath.pop() || '').replace(/(^View)|(\.vue$)/g, '');
    const fileTypeLowCase = fileType.toLowerCase();
    const typeSufix = `/${fileTypeLowCase}`;
    const pathEnd = (['add', 'edit', 'obj'].includes(fileTypeLowCase) && typeSufix) || '';
    const path = `/${arrPath.join('/')}${pathEnd}`;
    const bizIdent = arrPath.pop() || '';
    if (bizIdent && fileType) {
      const pathTitle = [...(subMetas[path] || []), metaTag[fileType]];
      return {
        path,
        name: `view${upFirst(bizIdent)}${fileType}`,
        meta: { pathTitle, bizIdent, group },
        component,
        props: true
      };
    } else {
      return null;
    }
  });
  return arrAll.filter((v: RouteRecordRaw | null) => !!v) as RouteRecordRaw[];
};
/** 从路由 name 判断crud状态 */
export const getStateByKey = (key: string): TCrudState =>  (key.endsWith('Add') && 'add')
|| (key.endsWith('Edit') && 'edit')
|| ((key.endsWith('View') || key.endsWith('Obj')) && 'view')
|| (key.endsWith('Edit') && 'edit')
|| 'list';

// (/Add$/.test(key) && 'add')
// || (/Edit$/.test(key) && 'edit')
// || (/View$/.test(key) && 'view')
// || (/Obj$/.test(key) && 'view')
// || 'list';

/** 从页面的路由键名，解析出业务标识和状态
 *
 * @param key 页面的路由键
 * @returns
 */
export const getPageByKey = (key: string): { bizIdent: string, state: TCrudState } => {
  const state = getStateByKey(key);
  const bizIdent = lowerFirst(key.replace(/^view/, '').replace(new RegExp(`${upFirst(state)}$`), '')).replace(/Obj$/, '');
  return { bizIdent, state };
};

/** 从 bizIdent 字符转换为路由缓存key
 *
 * @param bizIdent 业务标识
 * @param crudState 目标状态
 * @returns
 */
export const identToNavName = (bizIdent: string, crudState: TCrudState) => {
  const str = `view${upFirst(bizIdent)}${upFirst(crudState === 'view' ? 'obj' : crudState)}`;
  return str;
};

/** 从路由参数的上引下推参数中获取用于参数一致性比较的字符串，用于触发同一视图不同参数时的页面刷新
 *
 * @param params
 * @returns
 */
const getComparePushParameStr = (params?: IRouterPushParams): string => {
  if (!params) return '';
  const { bizIdent = { from: '', to: '' }, bizIdentDtl = { from: '', to: '' }, fromDtlIds = [], inPush, sameSourceKey } = params || {};
  return [
    bizIdent.from || '',
    bizIdent.to || '',
    bizIdentDtl.from || '',
    bizIdentDtl.to || '',
    fromDtlIds.sort((a: number | string, b: number | string) => +(a || -1) - +(b || -1)).join('_'),
    inPush ? 'inPush' : '',
    // (!inPush && '') || 'inPush',
    sameSourceKey || ''
  ].join('_');
};

/** 从路由参数获取用于参数一致性比较的字符串，用于触发同一视图不同参数时的页面刷新
 *
 * @param params
 */
export const getCompareParameStr = (params?: IRouterParam): string => {
  const { billId = -1, cloneId = -1, pushParam } = params || {};

  return [
    billId < 1 ? '' : billId,
    cloneId < 1 ? '' : cloneId,
    // (billId < 1 && '') || billId,
    // (cloneId < 1 && '') || cloneId,
    getComparePushParameStr(pushParam as IRouterPushParams | undefined)
  ].join('_');
};

/** 从 Map 实例中查找指定键名的顺序索引
 *
 * @param mapObj 要查找键名的 Map 实例
 * @param key 要查找顺序的键名
 * @returns
 */
export const getMapIndex = (mapObj: Map<string, any>, key: string): number => {
  let i = 0;
  for (const v of mapObj.entries()) {
    const [a] = v;
    if (a === key) {
      return i;
    }
    i++;
  }
  return -1;
};
