<template>
  <TPage :copyright-top-line="true" class="pageBizWrapper fullW-W fullH-H" :style="getMenuWidth">
    <!-- 左边栏菜单栏 -->
    <div class="menuZone rightLine">
      <div class="flexVXY py2 brandCell">
        <div class="logoZone flexSPlit"></div>
      </div>
      <div ref="menuContainer" style="border-top: 1px #f1efef solid;">
        <TMenu :data-menu="userMgr.getMenu" :height="menuHeight" @on-collapse="onCollapse" @on-nav="navMgr.navByMenu($event)"></TMenu>
      </div>
    </div>
    <!-- 右侧区域 -->
    <div class="bizZone overHidden">
      <!-- 顶部栏 -->
      <TNavHeader :bread-crumbs="navMgr.breadCrumbs.value" :curr-title="appMgr.getApp.appName"
      :user-info="userMgr.getUser" @on-sign-out="onSignOut" @on-pwd-change="allowRePwd">
        <a-alert v-show="networkErr" type="error">网络已断开</a-alert>
      </TNavHeader>
      <!-- 多页导航条 -->
      <TPageTabs :open-pages="navMgr.pageList.value" :active-key="navMgr.activeKey.value" @on-switch-page="navMgr.switchTag" @on-remove-tag="navMgr.removeTag"></TPageTabs>
      <!-- 工作区 -->
      <ViewWorkSpace>
        <router-view v-slot="{ Component }">
          <keep-alive :include="navMgr.pageInclude.value">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </ViewWorkSpace>
    </div>
    <!-- 修改密码 -->
    <a-modal v-model:visible="inPwdReset" unmount-on-close :mask-closable="false" :closable="false"
      :on-before-ok="execPwdChange" @cancel="cancelPwdChange">
      <PwdChange ref="pannelPwdChange"></PwdChange>
    </a-modal>
  </TPage>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import TPage from '@/components/layer/TPage.vue';
import TNavHeader from '@/components/widget/TNavHeader.vue';
import TMenu from '@/components/widget/menu/TMenuAcro.vue';
import TPageTabs from '@/components/widget/TPageTabs.vue';
import ViewWorkSpace from '@/components/layer/ViewWorkSpace.vue';
import PwdChange from '@/components/home/PwdChange.vue';
import { useApp } from '@/stores/sys/useApp';
import { useUser } from '@/stores/sys/useUser';
import { useNav } from '@/hook';
// import { apiInitChinaCitys } from '@/util/useApi';

const appMgr = useApp();
const userMgr = useUser();
const navMgr = useNav();

withDefaults(defineProps<{
  /** 是否网络异常 */
  networkErr: boolean
}>(), {
  networkErr: false
});

const emit = defineEmits<{
  (e: 'onSignOut'): void
}>();

// 菜单相关
/** 菜单是否已折叠 */
const menuContainer = ref<HTMLElement | null>(null);
const menuCollapsed = ref(false);
const menuHeight = ref(300);

// 多页签相关

// 密码重置
const inPwdReset = ref(false);
const pannelPwdChange = ref<HTMLElement | null>(null);

// #region 菜单响应
const getMenuWidth = computed(() => {
  return `grid-template-columns: ${menuCollapsed.value ? 49 : 257}px 1fr`;
});

const onCollapse = (e: boolean) => {
  menuCollapsed.value = e;
};
// #endregion

// #region 密码重置和退出登录
/** 启动密码修改窗口 */
const allowRePwd = () => {
  inPwdReset.value = true;
};

/** 执行密码重置 */
const execPwdChange = async () => {
  if (pannelPwdChange.value) {
    // @ts-ignore
    return !!(await pannelPwdChange.value.resetPwd());
  }
};
/** 取消密码重置 */
const cancelPwdChange = () => {
  // @ts-ignore
  pannelPwdChange.value.cancelRePwd();
  inPwdReset.value = false;
};

/** 响应退出登录 */
const onSignOut = async () => {
  await navMgr.destroy();
  emit('onSignOut');
};
// #endregion

onMounted(async () => {
  menuHeight.value = menuContainer.value?.clientHeight || 300;
  // await apiInitChinaCitys();
});
</script>

<style lang="scss" scoped>
.pageBizWrapper {
  display: grid;
  color: var(--color-text-1);
  background-color: var(--color-bg-1);
}

.menuZone {
  display: grid;
  grid-template-rows: 96px 1fr;
  user-select: none;

  .brandCell {
    position: relative;
    // background-color: var(--bg-color-brand);
    // background-color: hsl(93.55deg 36.16% 46.95% / 28%);
    // background-color: hsl(215.39deg 82.29% 69.71%);

    &::after {
      position: absolute;
      content: "";
      will-change: transform;
      /* transform: translate(-50%, -60%); */
      width: 220%;
      height: 88px;
      filter: blur(90px);
      opacity: 0.8;
      background-color: var(--proj-main-color);
    }

    .logoZone {
      width: 100%;
      height: 100%;
      background: transparent url(/static/logo/logo.png) center center no-repeat;
      background-size: contain;
      filter: drop-shadow(2px 4px 6px black);
    }
  }
}

.bizZone {
  position: relative;
  display: grid;
  grid-template-rows: 64px 32px 1fr;
  z-index: 900;
  background-color: transparent;

  .workZone {
    display: grid;
    grid-template-rows: 32px 1fr;
  }
}
</style>
