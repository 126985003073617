import type { IMenuItem, IUserSingnInfo } from 'shangshi_types';
import type { IUseUser, IUserCache } from '@/types/store/useUser';
import { KEY_MENU_CACHE, KEY_MENU_CACHE_FLAT, KEY_TOKEN, KEY_USER_CACHE } from '@/constant/sessionKey';
import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import { apiSignIn, apiSignOut, apiChangPwd } from '@/util/useApi';
import { startWorker } from '@/util/useWorker';
import { Toaster } from '@/util/uiKit/toaster';

const TIMER_KEY: string = 'timerId';
const TIMER_CACHE: Map<any, unknown> = new Map();
const showBirthDayTip = (userNamezh: string[]): Promise<void> => {
  return new Promise((resolve) => {
    const timerId = setTimeout(() => {
      Toaster.birthDayTip(userNamezh);
      resolve();
    }, 1000);

    TIMER_CACHE.set(Symbol.for(TIMER_KEY), timerId);
  });
};

/** 空用户信息模板 */
const emptyUserInfo = (): IUserCache => ({
  id: -1,
  code: '',
  namezh: '',
  avatar: '',
  employeeId: -1,
  deptId: -1,
  accType: -1
});

/** 从浏览器缓存读取用户信息 */
const getUserInfoFromCach = (): IUserCache => {
  const userStr = sessionStorage.getItem(KEY_USER_CACHE) || '';
  const empUser = emptyUserInfo();
  if (userStr) {
    const obj = Object.assign(empUser, JSON.parse(userStr)) as IUserCache;
    obj.id = +obj.id || -1;
    if (obj.id > 0) {
      return obj;
    } else {
      return empUser;
    }
  } else {
    return empUser;
  }
};

export const useUser = defineStore('useUser', (): IUseUser => {
  const state = reactive({
    /** 当前用户信息 */
    userInfo: emptyUserInfo(),
    sysMenus: [] as IMenuItem[],
    token: ''
  });

  /** 获取当前用户 */
  const getUser = computed<IUserCache>(() => {
    if ((!state.userInfo.code || !state.userInfo.namezh || !state.token || !(+state.userInfo.id > 1))) {
      state.userInfo = getUserInfoFromCach();
    }
    return state.userInfo;
  });

  const getToken = computed<string>(() => {
    return state.token || sessionStorage.getItem(KEY_TOKEN) || '';
  });

  /** 断言当前用户是否已登录 */
  const signStatus = computed<boolean>((): boolean => {
    const obj = !!(getToken.value && getUser.value.namezh && getUser.value.code && (+getUser.value.id > 0));
    return obj;
  });

  /** 清除当前的用户数据 */
  const userInit = () => {
    state.userInfo = emptyUserInfo();
  };

  const getMenu = computed<IMenuItem[]>(() => {
    if (state.sysMenus.length) {
      return state.sysMenus;
    } else {
      const menuStr = sessionStorage.getItem(KEY_MENU_CACHE) || '';
      if (!menuStr) return [];
      const arrMenu = JSON.parse(menuStr) as IMenuItem[];
      state.sysMenus = arrMenu as IMenuItem[];
      return arrMenu;
    }
  });

  /** 用户登录
   *
   * @param uName 用户名
   * @param uPwd 密码
   */
  const signIn = async (uName: string, uPwd: string): Promise<void> => {
    const { menus, ...signInedUser } = await apiSignIn(uName, uPwd) as IUserSingnInfo & { menus: IMenuItem[] };
    if (signInedUser) {
      const flatMenu = await startWorker<IMenuItem[], Record<string, IMenuItem>>('flatMenu', menus);
      sessionStorage.setItem(KEY_MENU_CACHE_FLAT, JSON.stringify(flatMenu));
      state.userInfo = Object.assign(emptyUserInfo(), signInedUser);
      // window.location.href = '/bizHome';
    }
  };
  /** 已登录用户注销 */
  const signOut = async () => {
    apiSignOut();
  };
  /** 用户注册
   *
   * @param paylod 注册数据载荷
   */
  const regist = async (paylod: { code: string, pwd: string }) => {
    console.log(paylod);
  };
  /** 更改密码
   *
   * @param oldPwd 旧密码
   * @param newPwd 新密码
   */
  const changePwd = async (oldPwd: string, newPwd: string) => {
    await apiChangPwd(oldPwd, newPwd);
  };
  /** 添加用户
   *
   * @param payload 待添加的用户数据载荷
   */
  const userAdd = async (payload: IUserCache) => {
    console.log(payload);
  };
  /** 修改用户信息
   *
   * @param paylod 要修改的用户信息数据载荷
   */
  const userEdit = async (paylod: Record<string, any>) => {
    console.log(paylod);
  };
  /** 停用账号
   *
   * @param userId 用户账号ID
   */
  const userStop = async (userId: number) => {
    console.log(userId);
  };

  /** 批量义序触发生日提醒 */
  const birthDayMention  = async (userNames: string[]) => {
    await showBirthDayTip(userNames);

    TIMER_CACHE.clear();
  };

  return {
    ...toRefs(state),
    getUser,
    signStatus,
    getMenu,
    userInit,
    signIn,
    signOut,
    regist,
    changePwd,
    userAdd,
    userEdit,
    userStop,
    birthDayMention,
    presist: true
  };
});
