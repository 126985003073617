export const PREFIX_BIZ = 'apiBiz';

export const HEADERS: Record<string, string> = {
  'x-fendside': 'web',
  'Access-Control-Allow-Origin': '*'
};

export const URL_TOKEN_LESS = ['/sign/in', '/sign/up'];

/** 格式化 url 地址，确保地址开头没有斜杠，结尾带有斜杠
 *  @param url 要格式化的 url 地址
 *  @param appendUrl url 地址格式化任务中，需要追加的地址片段
 */
export const fmtUrl = (url: string, ...appendUrl: (string | number)[]): string => {
  const arrUrl: string[] = [url.replace(/^\/|\/$/, '')];
  for (const v of appendUrl) {
    arrUrl.push(`${v}`.replace(/^\/|\/$/, ''));
  }
  return `${arrUrl.join('/')}/`;
};
